import { ChainId } from '@uniswap/sdk'
import { useMemo } from 'react'
import {
  LOQUIDITY_TOKEN_LIST,
  TELEPORT_TOKEN_LIST,
  TELEPORT_TARGET,
  TokenAddressMap,
  TeleportTargetMap
} from '../../constants/lists'
import useFlowTokenMetadata from '../../fcl-react/useFlowTokenMetadata'
import { Network, TokenInfo } from '../../types'
// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = NETWORK === 'mainnet' ? ChainId.MAINNET : ChainId.GÖRLI

export function useSwapTokenList(showLiquidityTokens = false): { [k: string]: TokenInfo } {
  const tokens = useFlowTokenMetadata()
  const tokenMap = useMemo(
    () =>
      Object.fromEntries(
        tokens.map(token => [token.address, { ...token, decimals: 8, chainId, network: Network.FLOW } as TokenInfo])
      ),
    [tokens]
  )
  return showLiquidityTokens ? LOQUIDITY_TOKEN_LIST[chainId] : tokenMap
}

export function useTeleportTokenList(): TokenAddressMap {
  return TELEPORT_TOKEN_LIST
}

export function useTeleportTargetList(): TeleportTargetMap {
  return TELEPORT_TARGET
}

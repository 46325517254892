import { ChainId } from '@uniswap/sdk'
import { BLT, FLOW_TOKEN, FUSD, TUSDT, REVV_FLOW, USDC_FLOW, STARLY_FLOW, SPRT_FLOW } from '../constants'
type UrlList = { readonly [chainId in ChainId]: string }

export const HANDSHARE_URL_LIST: UrlList = {
  [ChainId.MAINNET]: 'https://flow-wallet.blocto.app/authn',
  // [ChainId.RINKEBY]: 'http://localhost:8702/authn',
  [ChainId.RINKEBY]: 'https://flow-wallet-testnet.blocto.app/authn',
  [ChainId.ROPSTEN]: 'https://flow-wallet-testnet.blocto.app/authn',
  [ChainId.GÖRLI]: 'https://flow-wallet-testnet.blocto.app/authn',
  [ChainId.KOVAN]: 'https://flow-wallet-testnet.blocto.app/authn'
}

export const ACCESS_NODE_LIST: UrlList = {
  [ChainId.MAINNET]: 'https://access-mainnet-beta.onflow.org',
  // [ChainId.MAINNET]: 'https://flow-access-mainnet.portto.io',
  [ChainId.RINKEBY]: 'https://access-testnet.onflow.org',
  [ChainId.ROPSTEN]: 'https://access-testnet.onflow.org',
  [ChainId.GÖRLI]: 'https://access-testnet.onflow.org',
  [ChainId.KOVAN]: 'https://access-testnet.onflow.org'
}

// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = NETWORK === 'mainnet' ? ChainId.MAINNET : ChainId.GÖRLI

export const AVAILABLE_ROUTES = [
  {
    from: 'FLOW',
    to: 'BLT',
    path: [FLOW_TOKEN[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'BLT',
    to: 'FLOW',
    path: [BLT[chainId], TUSDT[chainId], FLOW_TOKEN[chainId]]
  },
  {
    from: 'FUSD',
    to: 'BLT',
    path: [FUSD[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'BLT',
    to: 'FUSD',
    path: [BLT[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'FLOW',
    to: 'FUSD',
    path: [FLOW_TOKEN[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'FUSD',
    to: 'FLOW',
    path: [FUSD[chainId], TUSDT[chainId], FLOW_TOKEN[chainId]]
  },
  {
    from: 'REVV',
    to: 'BLT',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'BLT',
    to: 'REVV',
    path: [BLT[chainId], TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'REVV',
    to: 'FUSD',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'FUSD',
    to: 'REVV',
    path: [FUSD[chainId], TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'REVV',
    to: 'tUSDT',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId]]
  },
  {
    from: 'tUSDT',
    to: 'REVV',
    path: [TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'USDC',
    to: 'FUSD',
    path: [USDC_FLOW[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'USDC',
    to: 'FLOW',
    path: [USDC_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId]]
  },
  {
    from: 'USDC',
    to: 'REVV',
    path: [USDC_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'USDC',
    to: 'BLT',
    path: [USDC_FLOW[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'FUSD',
    to: 'USDC',
    path: [FUSD[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'FLOW',
    to: 'USDC',
    path: [FLOW_TOKEN[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'REVV',
    to: 'USDC',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'BLT',
    to: 'USDC',
    path: [BLT[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'STARLY',
    to: 'REVV',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'STARLY',
    to: 'FLOW',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId]]
  },
  {
    from: 'STARLY',
    to: 'BLT',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'STARLY',
    to: 'FUSD',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'STARLY',
    to: 'USDC',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'REVV',
    to: 'STARLY',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'FLOW',
    to: 'STARLY',
    path: [FLOW_TOKEN[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'BLT',
    to: 'STARLY',
    path: [BLT[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'FUSD',
    to: 'STARLY',
    path: [FUSD[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'USDC',
    to: 'STARLY',
    path: [USDC_FLOW[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'SPRT',
    to: 'REVV',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId], REVV_FLOW[chainId]]
  },
  {
    from: 'SPRT',
    to: 'FLOW',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], FLOW_TOKEN[chainId]]
  },
  {
    from: 'SPRT',
    to: 'BLT',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], BLT[chainId]]
  },
  {
    from: 'SPRT',
    to: 'FUSD',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], FUSD[chainId]]
  },
  {
    from: 'SPRT',
    to: 'STARLY',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], STARLY_FLOW[chainId]]
  },
  {
    from: 'SPRT',
    to: 'USDC',
    path: [SPRT_FLOW[chainId], TUSDT[chainId], USDC_FLOW[chainId]]
  },
  {
    from: 'REVV',
    to: 'SPRT',
    path: [REVV_FLOW[chainId], FLOW_TOKEN[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  },
  {
    from: 'FLOW',
    to: 'SPRT',
    path: [FLOW_TOKEN[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  },
  {
    from: 'BLT',
    to: 'SPRT',
    path: [BLT[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  },
  {
    from: 'FUSD',
    to: 'SPRT',
    path: [FUSD[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  },
  {
    from: 'STARLY',
    to: 'SPRT',
    path: [STARLY_FLOW[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  },
  {
    from: 'USDC',
    to: 'SPRT',
    path: [USDC_FLOW[chainId], TUSDT[chainId], SPRT_FLOW[chainId]]
  }
]

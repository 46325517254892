import React, { useMemo } from 'react'
import { SwapPoolTabs } from '../../components/NavigationTabs'

import FullPositionCard from '../../components/PositionCard'

import { Pair } from '../../types'
import { useFclReact } from '../../fcl-react'
import { FLOW_TOKEN, TUSDT } from '../../constants'
import { useTokenBalances } from '../../state/wallet/flowHooks'
import { usePairs } from '../../data/Reserves'
import { toLiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Box, Flex, Text, Grid } from '@chakra-ui/react'
import Button from '../../components/ButtonPrimary'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyIcon } from '../../assets/images/no-transfer.svg'

export default function Pool() {
  const { account, chainId } = useFclReact()
  const { t } = useTranslation()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs
        .map(tokens => ({ liquidityToken: toLiquidityToken(tokens), tokens }))
        .filter(pairs => Boolean(pairs.liquidityToken)),
    [trackedTokenPairs]
  )

  // const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
  //   tokenPairsWithLiquidityTokens
  // ])
  const tokenBalances = useTokenBalances()

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) => (tokenBalances[liquidityToken.address] ?? 0) > 0),
    [tokenPairsWithLiquidityTokens, tokenBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading = v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity: Pair[] = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  return (
    <>
      <Box width="100%" maxWidth="600px">
        <SwapPoolTabs active={'pool'} />

        <Grid gap="20px" width="100%">
          <Box
            bg="background.primary"
            borderRadius="lg"
            boxShadow="0px 0px 20px 0px #0000000D"
            rounded="12"
            p="24px"
            mt="1rem"
          >
            <Flex justify={{ base: 'left', lg: 'space-between' }} flexWrap="wrap">
              <Box width={{ base: '100%', lg: '60%' }}>
                <Text color="font.primary" fontWeight={500}>
                  {t('liquidity.your_liquidity')}
                </Text>
                <Text mt="4px" color="font.secondary" fontWeight={400} fontSize={14}>
                  {t('liquidity.remove_detail')}
                </Text>
              </Box>
              <Box pt={{ base: '16px', lg: '0px' }}>
                <Button p="12px 16px" type="link" to={`/add/${FLOW_TOKEN[chainId].address}/${TUSDT[chainId].address}`}>
                  {t('liquidity.button.add')}
                </Button>
              </Box>
            </Flex>
          </Box>

          {!account ? (
            <Box
              bg="background.primary"
              borderRadius="lg"
              boxShadow="0px 0px 20px 0px #0000000D"
              rounded="12"
              p="24px"
              mt="1rem"
            >
              <Flex justify="center" align="center" flexWrap="wrap">
                <Box width={{ base: '100%', lg: '60%' }}>
                  <Text mt="88px" mb="88px" color="font.tertiary" fontWeight={400} fontSize={14} align="center">
                    {t('liquidity.connect_to_view')}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ) : v2IsLoading ? (
            <Box
              bg="background.primary"
              borderRadius="lg"
              boxShadow="0px 0px 20px 0px #0000000D"
              rounded="12"
              p="24px"
              mt="1rem"
            >
              <Flex justify="center" align="center" flexWrap="wrap">
                <Box width={{ base: '100%', lg: '60%' }}>
                  <Text mt="16px" mb="16px" color="font.tertiary" fontWeight={400} fontSize={14} align="center">
                    {t('liquidity.loading')}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ) : allV2PairsWithLiquidity?.length > 0 ? (
            <>
              {allV2PairsWithLiquidity.map((v2Pair, i) =>
                v2Pair?.liquidityToken ? (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} defaultIsOpen={i === 0} />
                ) : null
              )}
            </>
          ) : (
            <>
              <Box bg="background.primary" borderRadius="lg" boxShadow="0px 0px 20px 0px #0000000D" rounded="12">
                <Flex justify="center" flexWrap="wrap">
                  <Box pt="113px" pb="88px">
                    <EmptyIcon />
                    <Text mt="12px" color="font.tertiary" align="center" fontWeight={400} fontSize="14px">
                      {t('liquidity.not_found')}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </>
          )}
          <Box bg="background.tertiary" borderRadius="lg" rounded="12" p="16px" mt="8px">
            <Flex justify={{ base: 'left', lg: 'space-between' }} flexWrap="wrap">
              <Box>
                <Text color="font.secondary" fontSize="14px" fontWeight={400}>
                  {t('liquidity.by_adding')}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

const script = `\
import BloctoIdo from 0xIDOADDRESS

pub fun main(): { String: BloctoIdo.ActivityConfig } {
	var configs: { String: BloctoIdo.ActivityConfig } = {}
	let names = BloctoIdo.getActivityNames()
	for name in names {
		let config = BloctoIdo.getActivityConfig(name)
		if config != nil {
			configs[name] = config!
		}
	}
	return configs
}
`

export default script

const scriptBLT = `\
import TeleportCustodyAptos from 0xTELEPORTAPTOSADDRESS
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS

transaction(amount: UFix64, target: String) {
	// The TeleportUser reference for teleport operations
	let teleportUserRef: &TeleportCustodyAptos.TeleportAdmin{TeleportCustodyAptos.TeleportUser}

	// The Vault resource that holds the tokens that are being transferred
	let sentVault: @FungibleToken.Vault

	prepare(signer: AuthAccount) {
		self.teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS).getCapability(TeleportCustodyAptos.TeleportAdminTeleportUserPath)
			.borrow<&TeleportCustodyAptos.TeleportAdmin{TeleportCustodyAptos.TeleportUser}>()
			?? panic("Could not borrow a reference to TeleportOut")

		let vaultRef = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow a reference to the vault resource")

		self.sentVault <- vaultRef.withdraw(amount: amount)
	}

	execute {
		self.teleportUserRef.lock(from: <- self.sentVault, to: target.decodeHex())
	}
} 
`

const scripts: { [tokenName: string]: string } = {
  BLT: scriptBLT
}

export default scripts

import { ChainId, JSBI, Percent } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { TokenInfo, Network } from '../types'
import { fortmatic, injected, portis, walletconnect, walletlink, bloctosdk, bloctosdkBSC } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const PRODUCTION_URL = 'https://swap.blocto.app'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: TokenInfo[]
}

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 14
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const TELEPORT_ADDRESS_MAP: { [key: string]: string } = {
  // Ethereum Mainnet USDT
  '0xdAC17F958D2ee523a2206206994597C13D831ec7': '0xf8F12fE1B51D1398019C4faCd4D00aDAb5fEF746',

  // Rinkeby USDT
  '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0': '0xFfB71C8c8f309e86F527CD64db9a164f7d2a5a7b',

  // BSC Mainnet BLT
  '0x02Bdf640fba368E7Ba5c6429cCaF251512273865': '0x567f7048785fcEF7944B1C980AcbB32d0CA573B7',

  // BSC Testnet BLT
  '0xfB0727386DB1A630344a08467b45541bEC9bCf17': '0x5995e834B620544818268c31d1781B3E8953fBaf',

  // Ethereum Mainnet BLT
  '0x943ba7dafd835408743c716127eaa20e1bdf868a': '0x8D7D103DC7EFDD79B1fA3C9b77454387f03D71Bb',

  // Ethereum Goerli BLT
  '0xA690908e0a657c6a238779829f286696d18b113b': '0x5c4eB2C42ad326F827950403A1644A78Cb914C7b',

  // Ethereum Mainnet wFLOW
  '0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b': '0xe2733A335aB5B0F648A8b51d63Aa0335c135Ecfc',

  // Goerli wFLOW
  '0xaEa970De99544C22E4aCA3F4067b33453137A8D7': '0x1ed2c44492bf49Ae61e6c413dB1298c2aCE25760',
  // Goerli USDC
  '0x07865c6e87b9f70255377e024ace6630c1eaa37f': '0x1878d21683E29370961EB871B911D5a26765D2Fc',

  // BSC Mainnet/Testnet STARLY
  '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B': '0x58dc2aFB680845Fb93514cecf75F7008e9e6Cacf',

  // Ethereum Mainnet REVV
  '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca': '0x9Fd5F426038F9A6dac6347E5D26e5B336b241389',

  // Rinkeby REVV
  '0x591cda1fdDbBF1Ad3bd32cb9DFA096a44644f2DF': '0x5D7aFe35a711c0aE7683385B854e5704a3E896dB',

  // BSC Testnet ICONS
  '0x9ac373e338c5CDeE5F608b8F27B72B01a2564210': '0x4f7aC066347A6c3DE8Ef75F34a30b6713Ca55e70',

  // USDC Mainnet
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': '0x8f9c03411Ed4DCf742aAeC58330D7028f28b90DC',

  // USDC Testnet
  '0x8f9c03411Ed4DCf742aAeC58330D7028f28b90DC': '0x42cdB61b7D8ec6437b6b72C6B91Ae534C956678B',

  // SPRT Mainnet
  '0x5ab6A4F46Ce182356B6FA2661Ed8ebcAFce995aD': '0xB860DC121ba2B1A167034af4B0A4e6C349445Ae3',

  // SPRT Testnet
  '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a': '0x85babB87bABA1Ecb3C3b35cBD1AB402c8614C86C',

  // MOXY Testnet
  '0xEDe373D931df7209447EBe835226CacD33Abc3FD': '0x98d84776CEDa033Ef5a21784d7ac6C53abd30371',

  // MOXY BSC Testnet
  '0x840b236902a5aB28F507449fcA426dD8261761Ce': '0x34525a268BD96b1D709Aa897B7CD001dBDEeD58b'
}

export const CORE_SYMBOL_MAP: { [key: string]: string } = {
  wFLOW: 'FLOW',
  tUSDT: 'USDT'
}

export const FLOW_TOKEN: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x1654653399040a61',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x7e60df042a9c0868',
    name: 'Flow Token',
    decimals: 8,
    symbol: 'FLOW',
    coreSymbol: 'FLOW'
  }
}

export const TUSDT: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xcfdd90d4a00f7b5b',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT',
    coreSymbol: 'USDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT',
    coreSymbol: 'USDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT',
    coreSymbol: 'USDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT',
    coreSymbol: 'USDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xab26e0a07d770ec1',
    name: 'USD Tether',
    decimals: 8,
    symbol: 'tUSDT',
    coreSymbol: 'USDT'
  }
}

export const FUSD: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x3c5959b568896393',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD',
    coreSymbol: 'FUSD'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD',
    coreSymbol: 'FUSD'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD',
    coreSymbol: 'FUSD'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD',
    coreSymbol: 'FUSD'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xe223d8a629e49c68',
    name: 'Flow USD',
    decimals: 8,
    symbol: 'FUSD',
    coreSymbol: 'FUSD'
  }
}

export const MOXY_FLOW: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x678a363d069ccfe8',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x678a363d069ccfe8',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x678a363d069ccfe8',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x678a363d069ccfe8',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x678a363d069ccfe8',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  }
}

export const MOXY_SOLANA: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.SOLANA,
    chainId: ChainId.MAINNET,
    address: 'MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.RINKEBY]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.GÖRLI]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.KOVAN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  }
}

export const MOXY_BSC: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.BSC,
    chainId: ChainId.MAINNET,
    address: '0x840b236902a5aB28F507449fcA426dD8261761Ce',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.RINKEBY]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x840b236902a5aB28F507449fcA426dD8261761Ce',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x840b236902a5aB28F507449fcA426dD8261761Ce',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.GÖRLI]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x840b236902a5aB28F507449fcA426dD8261761Ce',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.KOVAN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x840b236902a5aB28F507449fcA426dD8261761Ce',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  }
}

export const MOXY_ETHEREUM: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0xEDe373D931df7209447EBe835226CacD33Abc3FD',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xEDe373D931df7209447EBe835226CacD33Abc3FD',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xEDe373D931df7209447EBe835226CacD33Abc3FD',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xEDe373D931df7209447EBe835226CacD33Abc3FD',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xEDe373D931df7209447EBe835226CacD33Abc3FD',
    name: 'Moxy Token',
    decimals: 8,
    symbol: 'MOXY',
    coreSymbol: 'MOXY'
  }
}

export const BLT: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x0f9df91c9121c460',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x6e0797ac987005f5',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  }
}

export const BLT_SOLANA: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.SOLANA,
    chainId: ChainId.MAINNET,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.SOLANA,
    chainId: ChainId.RINKEBY,
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  }
}

export const BLT_BSC: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.BSC,
    chainId: ChainId.MAINNET,
    address: '0x02Bdf640fba368E7Ba5c6429cCaF251512273865',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xfB0727386DB1A630344a08467b45541bEC9bCf17',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  }
}
export const BLT_ETHEREUM: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0x943ba7dafd835408743c716127eaa20e1bdf868a',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xA690908e0a657c6a238779829f286696d18b113b',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xA690908e0a657c6a238779829f286696d18b113b',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xA690908e0a657c6a238779829f286696d18b113b',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xA690908e0a657c6a238779829f286696d18b113b',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  }
}
export const BLT_APTOS: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.APTOS,
    chainId: ChainId.MAINNET,
    address: '0xfbab9fb68bd2103925317b6a540baa20087b1e7a7a4eb90badee04abb6b5a16f',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.RINKEBY]: {
    network: Network.APTOS,
    chainId: ChainId.RINKEBY,
    address: '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.APTOS,
    chainId: ChainId.RINKEBY,
    address: '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.GÖRLI]: {
    network: Network.APTOS,
    chainId: ChainId.RINKEBY,
    address: '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  },
  [ChainId.KOVAN]: {
    network: Network.APTOS,
    chainId: ChainId.RINKEBY,
    address: '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3',
    name: 'Blocto Token',
    decimals: 8,
    symbol: 'BLT',
    coreSymbol: 'BLT'
  }
}

export const STARLY_FLOW: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x142fa6570b62fd97',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf63219072aaddd50',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf63219072aaddd50',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf63219072aaddd50',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf63219072aaddd50',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  }
}

export const STARLY_BSC: { [chainId in ChainId | string]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.BSC,
    chainId: ChainId.MAINNET,
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.RINKEBY]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.ROPSTEN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.GÖRLI]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  },
  [ChainId.KOVAN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    name: 'Starly Token',
    decimals: 8,
    symbol: 'STARLY',
    coreSymbol: 'STARLY'
  }
}

export const USDT: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT',
    coreSymbol: 'USDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT',
    coreSymbol: 'USDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT',
    coreSymbol: 'USDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT',
    coreSymbol: 'USDT'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xc791ACb2E760Ac89f7E4b7699c355A2Ba4D4AFF0',
    name: 'USD Tether',
    decimals: 6,
    symbol: 'USDT',
    coreSymbol: 'USDT'
  }
}

export const WFLOW: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b',
    name: 'Wrapped FLOW',
    decimals: 18,
    symbol: 'wFLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x96b1e2b383feAd8f91386c19Cf88C4E214108193',
    name: 'Wrapped FLOW',
    decimals: 18,
    symbol: 'wFLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x96b1e2b383feAd8f91386c19Cf88C4E214108193',
    name: 'Wrapped FLOW',
    decimals: 18,
    symbol: 'wFLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0xaEa970De99544C22E4aCA3F4067b33453137A8D7',
    name: 'Wrapped FLOW',
    decimals: 18,
    symbol: 'wFLOW',
    coreSymbol: 'FLOW'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x96b1e2b383feAd8f91386c19Cf88C4E214108193',
    name: 'Wrapped FLOW',
    decimals: 18,
    symbol: 'wFLOW',
    coreSymbol: 'FLOW'
  }
}

export const FLOW_TUSDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xc6c77b9f5c7a378f',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'FLOW:tUSDT'
  }
}

export const BLT_TUSDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xfcb06a5ae5b21a2d',
    name: 'FLOW:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xc59604d4e65f14b3',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd9854329b7edf136',
    name: 'BLT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'BLT:tUSDT'
  }
}

export const REVV_FLOW_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x5e284fb7cff23a3f',
    name: 'REVV:FLOW Liquidity',
    decimals: 8,
    symbol: 'REVV:FLOW'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd017f81bffc9aa05',
    name: 'REVV:FLOW Liquidity',
    decimals: 8,
    symbol: 'REVV:FLOW'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd017f81bffc9aa05',
    name: 'REVV:FLOW Liquidity',
    decimals: 8,
    symbol: 'REVV:FLOW'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd017f81bffc9aa05',
    name: 'REVV:FLOW Liquidity',
    decimals: 8,
    symbol: 'REVV:FLOW'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xd017f81bffc9aa05',
    name: 'REVV:FLOW Liquidity',
    decimals: 8,
    symbol: 'REVV:FLOW'
  }
}

export const SPRT_USDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x60e1db11f37ac469',
    name: 'SPRT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'SPRT:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x0f5be7fc963db41b',
    name: 'SPRT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'SPRT:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x0f5be7fc963db41b',
    name: 'SPRT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'SPRT:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x0f5be7fc963db41b',
    name: 'SPRT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'SPRT:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x0f5be7fc963db41b',
    name: 'SPRT:tUSDT Liquidity',
    decimals: 8,
    symbol: 'SPRT:tUSDT'
  }
}

export const STARLY_USDT_LP: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x6efab66df92c37e4',
    name: 'STARLY:tUSDT Liquidity',
    decimals: 8,
    symbol: 'STARLY:tUSDT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x22d84efc93a8b21a',
    name: 'STARLY:tUSDT Liquidity',
    decimals: 8,
    symbol: 'STARLY:tUSDT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x22d84efc93a8b21a',
    name: 'STARLY:tUSDT Liquidity',
    decimals: 8,
    symbol: 'STARLY:tUSDT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x22d84efc93a8b21a',
    name: 'STARLY:tUSDT Liquidity',
    decimals: 8,
    symbol: 'STARLY:tUSDT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x22d84efc93a8b21a',
    name: 'STARLY:tUSDT Liquidity',
    decimals: 8,
    symbol: 'STARLY:tUSDT'
  }
}

export const REVV_FLOW: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xd01e482eb680ec9f',
    name: 'REVV Token',
    decimals: 8,
    symbol: 'REVV'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x14ca72fa4d45d2c3',
    name: 'REVV Token',
    decimals: 8,
    symbol: 'REVV'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x14ca72fa4d45d2c3',
    name: 'REVV Token',
    decimals: 8,
    symbol: 'REVV'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x14ca72fa4d45d2c3',
    name: 'REVV Token',
    decimals: 8,
    symbol: 'REVV'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0x14ca72fa4d45d2c3',
    name: 'REVV Token',
    decimals: 8,
    symbol: 'REVV'
  }
}

export const REVV_ETHEREUM: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca',
    name: 'REVV Token',
    decimals: 18,
    symbol: 'REVV'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x591cda1fdDbBF1Ad3bd32cb9DFA096a44644f2DF',
    name: 'REVV Token',
    decimals: 18,
    symbol: 'REVV'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x591cda1fdDbBF1Ad3bd32cb9DFA096a44644f2DF',
    name: 'REVV Token',
    decimals: 18,
    symbol: 'REVV'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x591cda1fdDbBF1Ad3bd32cb9DFA096a44644f2DF',
    name: 'REVV Token',
    decimals: 18,
    symbol: 'REVV'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x591cda1fdDbBF1Ad3bd32cb9DFA096a44644f2DF',
    name: 'REVV Token',
    decimals: 18,
    symbol: 'REVV'
  }
}

export const ICONS_FLOW: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xd01e482eb680ec9f',
    name: 'ICONS Token',
    decimals: 8,
    symbol: 'ICONS'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf446a5994b73b5f3',
    name: 'ICONS Token',
    decimals: 8,
    symbol: 'ICONS'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf446a5994b73b5f3',
    name: 'ICONS Token',
    decimals: 8,
    symbol: 'ICONS'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf446a5994b73b5f3',
    name: 'ICONS Token',
    decimals: 8,
    symbol: 'ICONS'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xf446a5994b73b5f3',
    name: 'ICONS Token',
    decimals: 8,
    symbol: 'ICONS'
  }
}

export const ICONS_BSC: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.BSC,
    chainId: ChainId.MAINNET,
    address: '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca',
    name: 'ICONS Token',
    decimals: 18,
    symbol: 'ICONS'
  },
  [ChainId.RINKEBY]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x9ac373e338c5CDeE5F608b8F27B72B01a2564210',
    name: 'ICONS Token',
    decimals: 18,
    symbol: 'ICONS'
  },
  [ChainId.ROPSTEN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x9ac373e338c5CDeE5F608b8F27B72B01a2564210',
    name: 'ICONS Token',
    decimals: 18,
    symbol: 'ICONS'
  },
  [ChainId.GÖRLI]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x9ac373e338c5CDeE5F608b8F27B72B01a2564210',
    name: 'ICONS Token',
    decimals: 18,
    symbol: 'ICONS'
  },
  [ChainId.KOVAN]: {
    network: Network.BSC,
    chainId: ChainId.RINKEBY,
    address: '0x9ac373e338c5CDeE5F608b8F27B72B01a2564210',
    name: 'ICONS Token',
    decimals: 18,
    symbol: 'ICONS'
  }
}

export const USDC_FLOW: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0xb19436aae4d94622',
    name: 'USD Coin',
    decimals: 8,
    symbol: 'USDC'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xa983fecbed621163',
    name: 'USD Coin',
    decimals: 8,
    symbol: 'USDC'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xa983fecbed621163',
    name: 'USD Coin',
    decimals: 8,
    symbol: 'USDC'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xa983fecbed621163',
    name: 'USD Coin',
    decimals: 8,
    symbol: 'USDC'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xa983fecbed621163',
    name: 'USD Coin',
    decimals: 8,
    symbol: 'USDC'
  }
}

export const USDC_ETHEREUM: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    name: 'USD Coin',
    decimals: 6,
    symbol: 'USDC'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x8f9c03411Ed4DCf742aAeC58330D7028f28b90DC',
    name: 'USD Coin',
    decimals: 6,
    symbol: 'USDC'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x8f9c03411Ed4DCf742aAeC58330D7028f28b90DC',
    name: 'USD Coin',
    decimals: 6,
    symbol: 'USDC'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    name: 'USD Coin',
    decimals: 6,
    symbol: 'USDC'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x8f9c03411Ed4DCf742aAeC58330D7028f28b90DC',
    name: 'USD Coin',
    decimals: 6,
    symbol: 'USDC'
  }
}

export const SPRT_FLOW: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.FLOW,
    chainId: ChainId.MAINNET,
    address: '0x475755d2c9dccc3a',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.RINKEBY]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xb097866d87d01550',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xb097866d87d01550',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.GÖRLI]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xb097866d87d01550',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.KOVAN]: {
    network: Network.FLOW,
    chainId: ChainId.RINKEBY,
    address: '0xb097866d87d01550',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  }
}

export const SPRT_ETHEREUM: { [chainId in ChainId]: TokenInfo } = {
  [ChainId.MAINNET]: {
    network: Network.ETHEREUM,
    chainId: ChainId.MAINNET,
    address: '0x5ab6A4F46Ce182356B6FA2661Ed8ebcAFce995aD',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.RINKEBY]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.ROPSTEN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.GÖRLI]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  },
  [ChainId.KOVAN]: {
    network: Network.ETHEREUM,
    chainId: ChainId.RINKEBY,
    address: '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a',
    name: 'Sportium Token',
    decimals: 18,
    symbol: 'SPRT'
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.MAINNET]: [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
  [ChainId.RINKEBY]: [FLOW_TOKEN[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.KOVAN]: []
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
  [ChainId.RINKEBY]: [FLOW_TOKEN[ChainId.RINKEBY], TUSDT[ChainId.RINKEBY]],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [],
  [ChainId.KOVAN]: []
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [TokenInfo, TokenInfo][] } = {
  [ChainId.MAINNET]: []
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  BLOCTOSDK: {
    connector: bloctosdk,
    name: 'Blocto',
    iconName: 'blocto.svg',
    description: 'Easiest mobile crypto wallet',
    href: null,
    color: '#1336BF',
    mobile: true
  },
  BLOCTOSDK_BSC: {
    connector: bloctosdkBSC,
    name: 'Blocto',
    iconName: 'blocto.svg',
    description: 'Easiest mobile crypto wallet',
    href: null,
    color: '#1336BF',
    mobile: true
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 100
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = 0.0001
export const BIPS_BASE = 10000
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW = 1.0 // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = 3.0 // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = 5.0 // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = 10.0 // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = 50.0 // 50%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const currentEnv = process.env.REACT_APP_NETWORK === 'mainnet' ? 'mainnet' : 'testnet'
const GTM_ARG_LIST = {
  testnet: {
    gtmId: 'GTM-MKCB64N',
    auth: 'Hxqhhcjn8EJ6jPNKDpSGZw',
    preview: 'env-2'
  },
  mainnet: {
    gtmId: 'GTM-MKCB64N',
    auth: 'zahmh_3KXz41qwkyavYbJw',
    preview: 'env-1'
  }
}

export const tagManagerArgs = GTM_ARG_LIST[currentEnv]

import { useEffect, useState } from 'react'
import { FlowTokenMetadata } from '../types'
import * as fcl from '@blocto/fcl'
import { replaceContractAddresses } from './env'
import { ETHREUM_CHAIN_ID_CONFING } from '../connectors'
// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = ETHREUM_CHAIN_ID_CONFING[NETWORK]

const fetchTokenMetadata = (): Promise<FlowTokenMetadata[]> => {
  const script = `\
  import ListedTokens from 0xBLOCTO_SWAP_LISING_ADDRESS

  pub fun main(): [ListedTokens.TokenInfo] {
    return ListedTokens.getTokens()
  }`
  return fcl.send([fcl.script(replaceContractAddresses(script, chainId))]).then(fcl.decode)
}

export default function useFlowTokenMetadata() {
  const [metadata, setMetadata] = useState<FlowTokenMetadata[]>([])

  useEffect(() => {
    fetchTokenMetadata().then(tokens => setMetadata(tokens))
  }, [])

  return metadata
}

import { ChainId } from '@uniswap/sdk'
import React from 'react'
import Modal from '../Modal'
import { ExternalLink } from '../../theme'
import { CloseIcon } from '../../theme/components'
import { ReactComponent as Circle } from '../../assets/images/blue-loader.svg'
import { ReactComponent as Success } from '../../assets/images/success-blue.svg'
import { ReactComponent as ErrorIcon } from '../../assets/images/error-red.svg'

import { Network } from '../../types'

import { getFvsLink, getEtherscanLink, getBSCscanLink, getSolanaExplorerLink, getAptosExplorerLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import { Box, Flex, Text, keyframes } from '@chakra-ui/react'
import Button from '../../components/ButtonPrimary'
import { useTranslation } from 'react-i18next'

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`
const spinAnimation = `${spin} infinite 2s linear`

export function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  const { t } = useTranslation()
  return (
    <Box w="100%" p="20px 26px">
      <Flex justify="flex-end">
        <CloseIcon onClick={onDismiss} />
      </Flex>
      <Flex justify="center" align="center" mt="44px" mb="20px" animation={spinAnimation}>
        <Circle />
      </Flex>
      <Flex flexDirection="column" flexWrap="wrap" align="center" justify="center">
        <Text color="font.primary" fontWeight={500} fontSize={20} mb="20px">
          {t('modal.wait_for_confirm')}
        </Text>
        <Text fontWeight={400} fontSize={14} color="font.primary">
          {pendingText}
        </Text>
        <Text fontSize={12} color="font.primary">
          {t('modal.confirm_in_wallet')}
        </Text>
      </Flex>
    </Box>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  network
}: {
  onDismiss: () => void
  hash: string
  chainId: ChainId
  network: string | undefined
}) {
  const { t } = useTranslation()
  const getLink = () => {
    switch (network) {
      default:
      case Network.FLOW:
        return { link: getFvsLink(chainId, hash, 'transaction'), text: t('popup.link.view_flowscan') }
      case Network.ETHEREUM:
        return { link: getEtherscanLink(chainId, hash, 'transaction'), text: t('popup.link.view_ethscan') }
      case Network.BSC:
        return { link: getBSCscanLink(chainId, hash, 'transaction'), text: t('popup.link.view_bscscan') }
      case Network.SOLANA:
        return {
          link: getSolanaExplorerLink(chainId, hash, 'tx'),
          text: t('popup.link.view_solanaexplor')
        }
      case Network.APTOS:
        return {
          link: getAptosExplorerLink(chainId, hash, 'txn'),
          text: t('popup.link.view_aptosexplor')
        }
    }
  }

  return (
    <Box w="100%" p="20px 26px">
      <Flex justify="flex-end">
        <CloseIcon onClick={onDismiss} />
      </Flex>
      <Flex justify="center" align="center" mt="44px" mb="20px">
        <Success />
      </Flex>
      <Flex flexWrap="wrap" align="center" justify="center">
        <Text color="font.primary" fontWeight={500} fontSize={20} w="100%" align="center">
          {t('modal.submitted')}
        </Text>
        {chainId && hash && (
          <ExternalLink href={getLink().link}>
            <Text color="font.highlight" fontWeight={400} fontSize={14}>
              {getLink().text}
            </Text>
          </ExternalLink>
        )}
        <Box mt="20px" w="100%">
          <Button onClick={onDismiss} height="54px" br="12px">
            <Text fontWeight={600} fontSize={16}>
              {t('modal.close')}
            </Text>
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Box w="100%">
      <Flex
        justify="space-between"
        p="16px 25px"
        position="sticky"
        top="0"
        bgColor="white"
        borderBottom="1px solid"
        borderColor="border.secondary"
        zIndex="overlay"
      >
        <Text fontWeight="500" fontSize="16px" color="font.primary">
          {title}
        </Text>
        <CloseIcon onClick={onDismiss} />
      </Flex>
      <Box p="24px">{topContent()}</Box>
      <Box p="24px" pt="0">
        {bottomContent()}
      </Box>
    </Box>
  )
}

export function TransactionErrorContent({
  message,
  title = 'Failed',
  onDismiss
}: {
  message: string
  title?: string
  onDismiss: () => void
}) {
  const { t } = useTranslation()
  return (
    <Box w="100%" p="20px 26px">
      <Flex justify="flex-end">
        <CloseIcon onClick={onDismiss} />
      </Flex>
      <Flex justify="center" align="center" mt="44px" mb="20px">
        <ErrorIcon />
      </Flex>

      <Text color="font.primary" mb="20px" fontWeight={500} fontSize={20} w="100%" align="center">
        {title}
      </Text>

      <Text color="font.primary" fontWeight={400} fontSize={14} w="100%" align="center">
        {message}
      </Text>
      <Box mt="20px" w="100%">
        <Button onClick={onDismiss} height="54px" br="12px">
          <Text fontWeight={600} fontSize={16}>
            {t('modal.close')}
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  trade?: any
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  trade
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          network={trade?.inputCurrency.network}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

const scriptUSDT = `\
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS).getCapability(/public/teleportedTetherTokenTeleportUser)
        .borrow<&TeleportedTetherToken.TeleportAdmin{TeleportedTetherToken.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- vault, to: targetAddress.decodeHex())
  }
}
`

const scriptFLOW = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportCustody from 0xTELEPORTWFLOWADDRESS

transaction(amount: UFix64, target: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORTWFLOWADMINADDRESS).getCapability(TeleportCustody.TeleportUserPublicPath)!
        .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount) as! @FlowToken.Vault;
    
    teleportUserRef.teleportOut(from: <- vault, to: target.decodeHex())
  }
}
`

const scriptREVV = `\
import REVV from 0xREVVTOKENADDRESS
import TeleportCustody from 0xREVVTELEPORTCUSTODYADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORTREVVADMINADDRESS).getCapability(TeleportCustody.TeleportUserPublicPath)
        .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- (vault as! @REVV.Vault), to: targetAddress.decodeHex())
  }
}
`

const scriptUSDC = `\
import FiatToken from 0xUSDC_TOKEN_ADDRESS
import TeleportCustodyEthereum from 0xUSDC_TELEPORT_ADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xUSDC_TELEPORT_ADMIN_ADDRESS).getCapability(TeleportCustodyEthereum.TeleportUserPublicPath)
        .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- (vault as! @FiatToken.Vault), to: targetAddress.decodeHex())
  }
}
`

const scriptSPRT = `\
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORT_SPRT_ADMIN_ADDRESS).getCapability(/public/TeleportedSportiumTokenTeleportUser)
        .borrow<&TeleportedSportiumToken.TeleportAdmin{TeleportedSportiumToken.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- vault, to: targetAddress.decodeHex())
  }
}
`

const scriptMOXY = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import MoxyToken from 0xMOXY_TOKEN_ADDRESS
import TeleportCustodyEthereum from 0xTELEPORT_MOXY_ADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let moxyTokenVaultRef = accu.borrow<&MoxyToken.Vault>(from: MoxyToken.moxyTokenVaultStorage)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- moxyTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodyEthereum.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>() ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scriptBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodyEthereum from 0xTELEPORT_ETHEREUM_BLT_ADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let blockTokenVaultRef = accu.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyEthereum.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>() ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptUSDT,
  FLOW: scriptFLOW,
  REVV: scriptREVV,
  USDC: scriptUSDC,
  SPRT: scriptSPRT,
  MOXY: scriptMOXY,
  BLT: scriptBLT
}

export default scripts

import React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { TYPE } from '../../theme'
import { ExternalLink } from '../../theme/components'
import { getFvsLink, getBSCscanLink, getEtherscanLink, getSolanaExplorerLink, getAptosExplorerLink } from '../../utils'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { Network } from '../../types'
import { Image } from '@chakra-ui/react'
import CheckBlue from '../../assets/icon/status/check-blue.svg'
import ErrorRed from '../../assets/icon/status/error-red.svg'
import { useTranslation } from 'react-i18next'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function TransactionPopup({
  hash,
  success,
  summary,
  network
}: {
  hash: string
  success?: boolean
  summary?: string
  network: string
}) {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const getLink = () => {
    switch (network) {
      default:
      case Network.FLOW:
        return { link: getFvsLink(chainId, hash, 'transaction'), text: t('popup.link.view_flowscan') }
      case Network.ETHEREUM:
        return { link: getEtherscanLink(chainId, hash, 'transaction'), text: t('popup.link.view_ethscan') }
      case Network.BSC:
        return { link: getBSCscanLink(chainId, hash, 'transaction'), text: t('popup.link.view_bscscan') }
      case Network.SOLANA:
        return {
          link: getSolanaExplorerLink(chainId, hash, 'tx'),
          text: t('popup.link.view_solanaexplor')
        }
      case Network.APTOS:
        return {
          link: getAptosExplorerLink(chainId, hash, 'txn'),
          text: t('popup.link.view_aptosexplor')
        }
    }
  }

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        <Image src={success ? CheckBlue : ErrorRed} boxSize="20px" />
      </div>
      <AutoColumn gap="0">
        <TYPE.body fontWeight={400}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</TYPE.body>
        {chainId && <ExternalLink href={getLink().link}>{getLink().text}</ExternalLink>}
      </AutoColumn>
    </RowNoFlex>
  )
}

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import 'inter-ui'
import React, { StrictMode, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { NetworkContextName } from './constants'
import './i18n'
import App from './pages/App'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import MulticallUpdater from './state/multicall/updater'
import FlowTxUpdater from './state/transactionsFlow/updater'
import EthereumTxUpdater from './state/transactionsEthereum/updater'
import BSCTxUpdater from './state/transactionsBsc/updater'
import SolanaTxUpdater from './state/transactionsSolana/updater'
import AptosTxUpdater from './state/transactionsAptos/updater'
import UserUpdater from './state/user/updater'
import WalletUpdater from './state/wallet/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import getLibrary from './utils/getLibrary'
import { SolanaContext } from './solana-react/SolanaContext'
import { AptosContext } from './aptos-react/AptosContext'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import boTheme from '@blocto/web-chakra-theme'
import componentTheme from './theme/chakraTheme'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  ;((window.ethereum as any) || {}).autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', error => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true
  })
})

function Updaters() {
  return (
    <>
      <UserUpdater />
      <ApplicationUpdater />
      <FlowTxUpdater />
      <EthereumTxUpdater />
      <BSCTxUpdater />
      <SolanaTxUpdater />
      <AptosTxUpdater />
      <MulticallUpdater />
      <WalletUpdater />
    </>
  )
}

function Root() {
  const [solana, setSolana] = useState(null)
  const [aptos, setAptos] = useState(null)
  return (
    <StrictMode>
      <FixedGlobalStyle />
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <AptosContext.Provider value={{ aptos, setAptos }}>
            <SolanaContext.Provider value={{ solana, setSolana }}>
              <Provider store={store}>
                <Updaters />
                <ThemeProvider>
                  <ChakraProvider theme={extendTheme({ ...boTheme, ...componentTheme })}>
                    <ThemedGlobalStyle />
                    <HashRouter>
                      <App />
                    </HashRouter>
                  </ChakraProvider>
                </ThemeProvider>
              </Provider>
            </SolanaContext.Provider>
          </AptosContext.Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

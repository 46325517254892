const scriptFlowUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return FlowSwapPair.totalSupply
}
`

const scriptFusdUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return FusdUsdtSwapPair.totalSupply
}
`

const scriptBltUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

pub fun main(): UFix64 {
    return BltUsdtSwapPair.totalSupply
}
`

const scriptRevvFlow = `\
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

pub fun main(): UFix64 {
    return RevvFlowSwapPair.totalSupply
}
`

const scriptStarlyUsdt = `\
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS

pub fun main(): UFix64 {
    return StarlyUsdtSwapPair.totalSupply
}
`

const scriptSprtUsdt = `\
import SprtUsdtSwapPair from 0xSPRT_USDT_SWAP_PAIR_ADDRESS

pub fun main(): UFix64 {
    return SprtUsdtSwapPair.totalSupply
}
`

const scripts: { [tokenName: string]: string } = {
  'FLOW:tUSDT': scriptFlowUsdt,
  'FUSD:tUSDT': scriptFusdUsdt,
  'BLT:tUSDT': scriptBltUsdt,
  'REVV:FLOW': scriptRevvFlow,
  'STARLY:tUSDT': scriptStarlyUsdt,
  'SPRT:tUSDT': scriptSprtUsdt
}

export default scripts

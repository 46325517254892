import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { BloctoConnector } from '@blocto/web3-react-connector'
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const NETWORK_URL = process.env.REACT_APP_ETHEREUM_RPC_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const ETHREUM_CHAIN_ID_CONFING: { [key: string]: number } = {
  mainnet: 1,
  testnet: 5
}

const BSC_CHAIN_ID_CONFING: { [key: string]: number } = {
  mainnet: 56,
  testnet: 97
}

const BSC_RPC_CONFING: { [key: string]: string } = {
  mainnet: 'https://bsc-dataseed1.binance.org',
  testnet: 'https://data-seed-prebsc-1-s1.binance.org:8545'
}

export const ETHEREUM_CHAIN_ID: number = ETHREUM_CHAIN_ID_CONFING[NETWORK] ?? 1
export const BSC_CHAIN_ID: number = BSC_CHAIN_ID_CONFING[NETWORK] ?? 1

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_ETHEREUM_RPC_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: {
    [ETHEREUM_CHAIN_ID]: NETWORK_URL,
    [BSC_CHAIN_ID]: BSC_RPC_CONFING[NETWORK]
  },
  defaultChainId: ETHEREUM_CHAIN_ID
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97]
})

export const bloctosdk = new BloctoConnector({
  chainId: ETHEREUM_CHAIN_ID,
  rpc: NETWORK_URL
})

export const bloctosdkBSC = new BloctoConnector({
  chainId: BSC_CHAIN_ID,
  rpc: ''
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})

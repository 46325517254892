import { useEffect, useState } from 'react'
import * as fcl from '@blocto/fcl'
import { FlowTokenMetadata, Network, PairDetail } from '../types'
import useFlowTokenMetadata from './useFlowTokenMetadata'
import { replaceContractAddresses } from './env'
import { ETHREUM_CHAIN_ID_CONFING } from '../connectors'
// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = ETHREUM_CHAIN_ID_CONFING[NETWORK]

const scriptTemplate = `\
import ListedPairs from 0xBLOCTO_SWAP_LISING_ADDRESS

pub fun main(): [ListedPairs.PairInfo] {
  return ListedPairs.getPairs()
}`

const fetchPairs = (tokens: Array<FlowTokenMetadata>): Promise<PairDetail[]> => {
  const findToken = (name: string) => {
    const found = tokens.find(token => token.name === name)
    if (!found) return null
    return {
      ...found,
      name: found.displayName,
      network: Network.FLOW,
      chainId,
      decimals: 8
    }
  }

  return fcl
    .send([fcl.script(replaceContractAddresses(scriptTemplate, chainId))])
    .then(fcl.decode)
    .then((result: any[]) =>
      result.map(item => ({
        ...item,
        token0: findToken(item.token0),
        token1: findToken(item.token1),
        liquidityToken: findToken(item.liquidityToken)
      }))
    )
}

export default function useSwapPairs() {
  const [swapPairs, setSwapPairs] = useState<PairDetail[]>([])
  const tokens = useFlowTokenMetadata()

  useEffect(() => {
    fetchPairs(tokens).then(pairs => setSwapPairs(pairs))
  }, [tokens])

  return swapPairs
}

import React, { useState, useEffect } from 'react'
import { Box, Flex, Image, Center, Button } from '@chakra-ui/react'
import BackIcon from '../../assets/icon/navigation/arror_back.svg'
import BloctoIcon from '../../assets/images/blocto_new.svg'
import CloseIcon from '../../assets/icon/navigation/close.svg'
import { ListItem, TitleBox } from './layout'
import { motion, AnimatePresence } from 'framer-motion'
import { useFclReact } from '../../fcl-react'
import { useSolana } from '../../solana-react'
import { useAptos } from '../../aptos-react'
import { FlowCard, EthereumCard, BSCCard, SolanaCard, AptosCard } from './ChainCards'
import { Network } from '../../types'
import Option from './Option'
import { useWeb3React } from '@web3-react/core'
import { SUPPORTED_WALLETS } from '../../constants'
import { isMobile } from 'react-device-detect'
import { injected, fortmatic, portis, ETHREUM_CHAIN_ID_CONFING } from '../../connectors'
import { OVERLAY_READY } from '../../connectors/Fortmatic'
import { AbstractConnector } from '@web3-react/abstract-connector'
import ReactGA from 'react-ga'
import MetamaskIcon from '../../assets/images/metamask.png'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useTranslation } from 'react-i18next'
import { currentEnv } from '../../constants'
import { isEvmChain } from '../../utils'

enum View {
  chain,
  wallet
}

const ConnectOptions = ({ network, onClose }: { network: Network; onClose: () => void }) => {
  const { connect: connectFcl, disconnect: disconnectFcl, account: fclAccount } = useFclReact()
  const { connect: connectSol, disconnect: disconnectSol, account: solAccount } = useSolana()
  const { connect: connectAptos, disconnect: disconnectAptos, account: aptosAccount } = useAptos()
  const { connector, activate, chainId } = useWeb3React()
  // close wallet modal if fortmatic modal is active
  useEffect(() => {
    fortmatic.on(OVERLAY_READY, () => {
      onClose()
    })
  }, [onClose])

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = ''
    Object.keys(SUPPORTED_WALLETS).map(key => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: name
    })

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, undefined, true).catch(error => {
        if (error) {
          console.error('error: ', error)
          activate(connector) // a little janky...can't use setError because the connector isn't set
        }
      })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions(type?: 'BSC' | 'ETHEREUM') {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isBlocto
    return Object.keys(SUPPORTED_WALLETS)
      .filter(key => {
        const connector = SUPPORTED_WALLETS[key].connector
        const supportedChainIds = (connector && connector.supportedChainIds) || []
        if (network === Network.ETHEREUM) {
          return !!supportedChainIds.find((id: any) => isEvmChain(id))
        } else if (network === Network.BSC) {
          return !!supportedChainIds.find((id: any) => id === 56 || id === 97)
        }
        return false
      })
      .map(key => {
        const option = SUPPORTED_WALLETS[key]
        // check for mobile options
        if (isMobile) {
          //disable portis on mobile for now
          if (option.connector === portis) {
            return null
          }

          if (option.mobile || option.name === 'Blocto') {
            return (
              <Option
                onClick={() => {
                  onClose()
                  option.connector !== connector && !option.href && tryActivation(option.connector)
                }}
                id={`connect-${key}`}
                key={key}
                active={option.connector && option.connector === connector}
                color={option.color}
                link={option.href}
                header={option.name}
                subheader={null}
                icon={require('../../assets/images/' + option.iconName)}
              />
            )
          }
          return null
        }

        // overwrite injected when needed
        if (option.connector === injected) {
          // don't show injected if there's no injected provider
          if (!(window.web3 || window.ethereum)) {
            if (option.name === 'MetaMask') {
              return (
                <Option
                  id={`connect-${key}`}
                  key={key}
                  color={'#E8831D'}
                  header={'MetaMask'}
                  subheader={null}
                  link={'https://metamask.io/'}
                  icon={MetamaskIcon}
                />
              )
            } else {
              return null //dont want to return install twice
            }
          }
          // don't return metamask if injected provider isn't metamask
          else if (option.name === 'MetaMask' && !isMetamask) {
            return null
          }

          // likewise for generic
          else if (option.name === 'Injected' && isMetamask) {
            return null
          }
        }

        if (option.name === 'MetaMask') {
          return (
            <Option
              id={`connect-${key}`}
              onClick={() => {
                onClose()
                if (
                  type === 'ETHEREUM' &&
                  chainId !== ETHREUM_CHAIN_ID_CONFING['mainnet'] &&
                  chainId !== ETHREUM_CHAIN_ID_CONFING['testnet']
                ) {
                  window.ethereum?.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${ETHREUM_CHAIN_ID_CONFING[currentEnv]}` }]
                  })
                }
                if (type === 'BSC' && chainId !== 56 && chainId !== 97) {
                  window.ethereum?.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: currentEnv === 'mainnet' ? '0x56' : '0x61' }]
                  })
                }
                !option.href && tryActivation(option.connector)
              }}
              key={key}
              active={option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null} //use option.descriptio to bring back multi-line
              icon={require('../../assets/images/' + option.iconName)}
            />
          )
        }

        // return rest of options
        return (
          !isMobile &&
          !option.mobileOnly && (
            <Option
              id={`connect-${key}`}
              onClick={() => {
                onClose()
                !option.href && tryActivation(option.connector)
              }}
              key={key}
              active={option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null} //use option.descriptio to bring back multi-line
              icon={require('../../assets/images/' + option.iconName)}
            />
          )
        )
      })
  }

  switch (network) {
    default:
      return null
    case Network.FLOW:
      return (
        <ListItem
          onClick={() => {
            if (fclAccount) {
              disconnectFcl()
            }
            connectFcl()
            onClose()
          }}
        >
          <Image src={BloctoIcon} alt="Blocto" boxSize="32px" mr="12px" />
          Blocto
        </ListItem>
      )
    case Network.SOLANA:
      return (
        <ListItem
          clickable="pointer"
          onClick={() => {
            if (solAccount) {
              disconnectSol()
            }
            connectSol()
            onClose()
          }}
        >
          <Image src={BloctoIcon} alt="Blocto" boxSize="32px" mr="12px" />
          Blocto
        </ListItem>
      )
    case Network.ETHEREUM:
      return <>{getOptions('ETHEREUM')}</>
    case Network.BSC:
      return <>{getOptions('BSC')}</>
    case Network.APTOS:
      return (
        <ListItem
          clickable="pointer"
          onClick={() => {
            if (aptosAccount) {
              disconnectAptos()
            }
            connectAptos()
            onClose()
          }}
        >
          <Image src={BloctoIcon} alt="Blocto" boxSize="32px" mr="12px" />
          Blocto
        </ListItem>
      )
  }
}

const WalletModal = ({ onClose, isTeleport }: { onClose: () => void; isTeleport?: boolean }) => {
  const [view, setView] = useState<View>(View.chain)
  const [chain, setChain] = useState<Network>(Network.FLOW)
  const { t } = useTranslation()

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Box backgroundColor="white">
        {view === View.chain && (
          <>
            <motion.div
              key="chain"
              initial={{ x: '-50%' }}
              animate={{ x: 0 }}
              exit={{ x: '-50%' }}
              transition={{ ease: 'easeOut' }}
            >
              <TitleBox>
                {t('header.wallet.title')}
                <Center position="absolute" right="27px" onClick={onClose}>
                  <Button>
                    <Image src={CloseIcon} boxSize="15px" />
                  </Button>
                </Center>
              </TitleBox>
              <Flex p="24px" width="100%" flexDirection="column" gap="12px">
                <FlowCard
                  onNextStep={() => {
                    setChain(Network.FLOW)
                    setView(View.wallet)
                  }}
                  onClose={() => {
                    setView(View.chain)
                    onClose()
                  }}
                />
                {isTeleport && (
                  <>
                    <EthereumCard
                      onNextStep={() => {
                        setChain(Network.ETHEREUM)
                        setView(View.wallet)
                      }}
                      onClose={() => {
                        setView(View.chain)
                        onClose()
                      }}
                    />
                    <BSCCard
                      onNextStep={() => {
                        setChain(Network.BSC)
                        setView(View.wallet)
                      }}
                      onClose={() => {
                        setView(View.chain)
                        onClose()
                      }}
                    />
                    <SolanaCard
                      onNextStep={() => {
                        setChain(Network.SOLANA)
                        setView(View.wallet)
                      }}
                      onClose={() => {
                        setView(View.chain)
                        onClose()
                      }}
                    />
                    <AptosCard
                      onNextStep={() => {
                        setChain(Network.APTOS)
                        setView(View.wallet)
                      }}
                      onClose={() => {
                        setView(View.chain)
                        onClose()
                      }}
                    />
                  </>
                )}
              </Flex>
            </motion.div>
          </>
        )}
        {view === View.wallet && (
          <>
            <motion.div
              key="wallet"
              initial={{ x: '50%' }}
              animate={{ x: 0 }}
              exit={{ x: '50%' }}
              transition={{ ease: 'easeOut' }}
            >
              <TitleBox>
                <Box
                  mr="8px"
                  cursor="pointer"
                  onClick={() => {
                    setView(View.chain)
                  }}
                >
                  <motion.div initial={{ width: '0' }} animate={{ width: '20px' }}>
                    <Image boxSize="20px" src={BackIcon} alt="Back" />
                  </motion.div>
                </Box>
                {t('header.wallet.title_choose')}
              </TitleBox>
              <Flex p="24px" width="100%" flexDirection="column" gap="12px">
                <ConnectOptions network={chain} onClose={onClose} />
              </Flex>
            </motion.div>
          </>
        )}
      </Box>
    </AnimatePresence>
  )
}

export default WalletModal

import { useEffect, useState, useContext, useMemo } from 'react'
import { SolanaContext } from './SolanaContext'
import { useSolana } from './useSolana'
import { BLT_TOKEN_MINT, MOXY_TOKEN_MINT } from './env'

export function useTokenBalances(
  address: string | undefined,
  nonce?: number | undefined
): { [tokenAddress: string]: number | undefined } {
  const [balances, setBalances] = useState<{ [tokenAddress: string]: number | undefined }>({})
  const { solana } = useContext<any>(SolanaContext)
  const solAccount = useSolana().account

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    solana &&
      solana
        .request({
          method: 'getTokenAccountsByOwner',
          params: [
            solAccount,
            {
              mint: BLT_TOKEN_MINT.toString()
            },
            {
              encoding: 'jsonParsed'
            }
          ]
        })
        .then((res: any) => {
          const bal = res?.value[0]?.account?.data.parsed.info.tokenAmount.uiAmount || 0
          isSubscribed &&
            setBalances(prev => {
              return { ...prev, [BLT_TOKEN_MINT.toString()]: bal }
            })
        })

    solana &&
      solana
        .request({
          method: 'getTokenAccountsByOwner',
          params: [
            solAccount,
            {
              mint: MOXY_TOKEN_MINT.toString()
            },
            {
              encoding: 'jsonParsed'
            }
          ]
        })
        .then((res: any) => {
          const bal = res?.value[0]?.account?.data.parsed.info.tokenAmount.uiAmount || 0
          isSubscribed &&
            setBalances(prev => {
              return { ...prev, [MOXY_TOKEN_MINT.toString()]: bal }
            })
        })

    return callback
  }, [solAccount, solana, address, nonce])

  return useMemo(() => balances, [balances])
}

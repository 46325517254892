const scriptAddFlowUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: &FlowToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &FlowSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.flowTokenVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-FlowSwapPair.createEmptyVault(), to: FlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FlowSwapPair.Vault{FungibleToken.Receiver}>(
        FlowSwapPair.TokenPublicReceiverPath,
        target: FlowSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FlowSwapPair.Vault{FungibleToken.Balance}>(
        FlowSwapPair.TokenPublicBalancePath,
        target: FlowSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = FlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.flowTokenVaultRef.withdraw(amount: token1Amount) as! @FlowToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- FlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- FlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtFlow = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: &FlowToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &FlowSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.flowTokenVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-FlowSwapPair.createEmptyVault(), to: FlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&FlowSwapPair.Vault{FungibleToken.Receiver}>(
        FlowSwapPair.TokenPublicReceiverPath,
        target: FlowSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&FlowSwapPair.Vault{FungibleToken.Balance}>(
        FlowSwapPair.TokenPublicBalancePath,
        target: FlowSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = FlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.flowTokenVaultRef.withdraw(amount: token1Amount) as! @FlowToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- FlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- FlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddBltUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenRef: &BloctoToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &BltUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.bloctoTokenRef = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-BltUsdtSwapPair.createEmptyVault(), to: BltUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BltUsdtSwapPair.Vault{FungibleToken.Receiver}>(
        BltUsdtSwapPair.TokenPublicReceiverPath,
        target: BltUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BltUsdtSwapPair.Vault{FungibleToken.Balance}>(
        BltUsdtSwapPair.TokenPublicBalancePath,
        target: BltUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.bloctoTokenRef.withdraw(amount: token1Amount) as! @BloctoToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- BltUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- BltUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtBlt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenRef: &BloctoToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &BltUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.bloctoTokenRef = signer.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-BltUsdtSwapPair.createEmptyVault(), to: BltUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BltUsdtSwapPair.Vault{FungibleToken.Receiver}>(
        BltUsdtSwapPair.TokenPublicReceiverPath,
        target: BltUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BltUsdtSwapPair.Vault{FungibleToken.Balance}>(
        BltUsdtSwapPair.TokenPublicBalancePath,
        target: BltUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.bloctoTokenRef.withdraw(amount: token1Amount) as! @BloctoToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- BltUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- BltUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

// const scriptAddUsdtFusd = `
// import FungibleToken from 0xFUNGIBLETOKENADDRESS
// import FUSD from 0xFUSDADDRESS
// import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
// import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

// transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
//   prepare(signer: AuthAccount) {
//     let poolAmounts = FusdUsdtSwapPair.getPoolAmounts()

//     let token1Percentage = token1In / poolAmounts.token1Amount
//     let token2Percentage = token2In / poolAmounts.token2Amount

//     let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

//     let token1Amount = finalPercentage * poolAmounts.token1Amount
//     let token2Amount = finalPercentage * poolAmounts.token2Amount

//     assert(token1Amount > token1Min, message: "Token 1 amount too small")
//     assert(token2Amount > token2Min, message: "Token 2 amount too small")

//     let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
//         ?? panic("Could not borrow a reference to Vault")

//     let token1Vault <- fusdVault.withdraw(amount: token1Amount) as! @FUSD.Vault

//     let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
//         ?? panic("Could not borrow a reference to Vault")

//     let token2Vault <- tetherVault.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

//     let tokenBundle <- FusdUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
//     let liquidityTokenVault <- FusdUsdtSwapPair.addLiquidity(from: <- tokenBundle)

//     if signer.borrow<&FusdUsdtSwapPair.Vault>(from: /storage/fusdUsdtFspLpVault) == nil {
//       // Create a new flowToken Vault and put it in storage
//       signer.save(<-FusdUsdtSwapPair.createEmptyVault(), to: /storage/fusdUsdtFspLpVault)

//       // Create a public capability to the Vault that only exposes
//       // the deposit function through the Receiver interface
//       signer.link<&FusdUsdtSwapPair.Vault{FungibleToken.Receiver}>(
//         /public/fusdUsdtFspLpReceiver,
//         target: /storage/fusdUsdtFspLpVault
//       )

//       // Create a public capability to the Vault that only exposes
//       // the balance field through the Balance interface
//       signer.link<&FusdUsdtSwapPair.Vault{FungibleToken.Balance}>(
//         /public/fusdUsdtFspLpBalance,
//         target: /storage/fusdUsdtFspLpVault
//       )
//     }

//     let liquidityTokenRef = signer.borrow<&FusdUsdtSwapPair.Vault>(from: /storage/fusdUsdtFspLpVault)
//         ?? panic("Could not borrow a reference to Vault")

//     liquidityTokenRef.deposit(from: <- liquidityTokenVault)
//   }
// }
// `

// const scriptAddFusdUsdt = `
// import FungibleToken from 0xFUNGIBLETOKENADDRESS
// import FUSD from 0xFUSDADDRESS
// import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
// import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

// transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
//   prepare(signer: AuthAccount) {
//     let poolAmounts = FusdUsdtSwapPair.getPoolAmounts()

//     let token1Percentage = token1In / poolAmounts.token1Amount
//     let token2Percentage = token2In / poolAmounts.token2Amount

//     let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

//     let token1Amount = finalPercentage * poolAmounts.token1Amount
//     let token2Amount = finalPercentage * poolAmounts.token2Amount

//     token1Amount = token1Amount < token1In ? token1Amount : token1In
//     token2Amount = token2Amount < token2In ? token2Amount : token2In

//     assert(token1Amount > token1Min, message: "Token 1 amount too small")
//     assert(token2Amount > token2Min, message: "Token 2 amount too small")

//     let fusdVault = signer.borrow<&FUSD.Vault>(from: /storage/fusdVault)
//         ?? panic("Could not borrow a reference to Vault")

//     let token1Vault <- fusdVault.withdraw(amount: token1Amount) as! @FUSD.Vault

//     let tetherVault = signer.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
//         ?? panic("Could not borrow a reference to Vault")

//     let token2Vault <- tetherVault.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

//     let tokenBundle <- FusdUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
//     let liquidityTokenVault <- FusdUsdtSwapPair.addLiquidity(from: <- tokenBundle)

//     if signer.borrow<&FusdUsdtSwapPair.Vault>(from: /storage/fusdUsdtFspLpVault) == nil {
//       // Create a new flowToken Vault and put it in storage
//       signer.save(<-FusdUsdtSwapPair.createEmptyVault(), to: /storage/fusdUsdtFspLpVault)

//       // Create a public capability to the Vault that only exposes
//       // the deposit function through the Receiver interface
//       signer.link<&FusdUsdtSwapPair.Vault{FungibleToken.Receiver}>(
//         /public/fusdUsdtFspLpReceiver,
//         target: /storage/fusdUsdtFspLpVault
//       )

//       // Create a public capability to the Vault that only exposes
//       // the balance field through the Balance interface
//       signer.link<&FusdUsdtSwapPair.Vault{FungibleToken.Balance}>(
//         /public/fusdUsdtFspLpBalance,
//         target: /storage/fusdUsdtFspLpVault
//       )
//     }

//     let liquidityTokenRef = signer.borrow<&FusdUsdtSwapPair.Vault>(from: /storage/fusdUsdtFspLpVault)
//         ?? panic("Could not borrow a reference to Vault")

//     liquidityTokenRef.deposit(from: <- liquidityTokenVault)
//   }
// }
// `

const scriptAddRevvFlow = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let revvVaultRef: &REVV.Vault
  let flowTokenVaultRef: &FlowToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &RevvFlowSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.revvVaultRef = signer.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-RevvFlowSwapPair.createEmptyVault(), to: RevvFlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&RevvFlowSwapPair.Vault{FungibleToken.Receiver}> (
        RevvFlowSwapPair.TokenPublicReceiverPath,
        target: RevvFlowSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&RevvFlowSwapPair.Vault{FungibleToken.Balance}> (
        RevvFlowSwapPair.TokenPublicBalancePath,
        target: RevvFlowSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = RevvFlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.revvVaultRef.withdraw(amount: token1Amount) as! @REVV.Vault
    let token2Vault <- self.flowTokenVaultRef.withdraw(amount: token2Amount) as! @FlowToken.Vault

    let tokenBundle <- RevvFlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- RevvFlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddFlowRevv = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let revvVaultRef: &REVV.Vault
  let flowTokenVaultRef: &FlowToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &RevvFlowSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.revvVaultRef = signer.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-RevvFlowSwapPair.createEmptyVault(), to: RevvFlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&RevvFlowSwapPair.Vault{FungibleToken.Receiver}> (
        RevvFlowSwapPair.TokenPublicReceiverPath,
        target: RevvFlowSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&RevvFlowSwapPair.Vault{FungibleToken.Balance}> (
        RevvFlowSwapPair.TokenPublicBalancePath,
        target: RevvFlowSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = RevvFlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.revvVaultRef.withdraw(amount: token1Amount) as! @REVV.Vault
    let token2Vault <- self.flowTokenVaultRef.withdraw(amount: token2Amount) as! @FlowToken.Vault

    let tokenBundle <- RevvFlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- RevvFlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddStarlyUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let starlyVaultRef: &StarlyToken.Vault
  let usdtTokenVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &StarlyUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.starlyVaultRef = signer.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.usdtTokenVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-StarlyUsdtSwapPair.createEmptyVault(), to: StarlyUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&StarlyUsdtSwapPair.Vault{FungibleToken.Receiver}> (
        StarlyUsdtSwapPair.TokenPublicReceiverPath,
        target: StarlyUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&StarlyUsdtSwapPair.Vault{FungibleToken.Balance}> (
        StarlyUsdtSwapPair.TokenPublicBalancePath,
        target: StarlyUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = StarlyUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.starlyVaultRef.withdraw(amount: token1Amount) as! @StarlyToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- StarlyUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- StarlyUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtStarly = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let starlyVaultRef: &StarlyToken.Vault
  let usdtTokenVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &StarlyUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.starlyVaultRef = signer.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.usdtTokenVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-StarlyUsdtSwapPair.createEmptyVault(), to: StarlyUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&StarlyUsdtSwapPair.Vault{FungibleToken.Receiver}> (
        StarlyUsdtSwapPair.TokenPublicReceiverPath,
        target: StarlyUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&StarlyUsdtSwapPair.Vault{FungibleToken.Balance}> (
        StarlyUsdtSwapPair.TokenPublicBalancePath,
        target: StarlyUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = StarlyUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.starlyVaultRef.withdraw(amount: token1Amount) as! @StarlyToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- StarlyUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- StarlyUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddSprtUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import SprtUsdtSwapPair from 0xSPRT_USDT_SWAP_PAIR_ADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let sprtVaultRef: &TeleportedSportiumToken.Vault
  let usdtTokenVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &SprtUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.sprtVaultRef = signer.borrow<&TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.usdtTokenVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&SprtUsdtSwapPair.Vault>(from: SprtUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-SprtUsdtSwapPair.createEmptyVault(), to: SprtUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&SprtUsdtSwapPair.Vault{FungibleToken.Receiver}> (
        SprtUsdtSwapPair.TokenPublicReceiverPath,
        target: SprtUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&SprtUsdtSwapPair.Vault{FungibleToken.Balance}> (
        SprtUsdtSwapPair.TokenPublicBalancePath,
        target: SprtUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&SprtUsdtSwapPair.Vault>(from: SprtUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = SprtUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.sprtVaultRef.withdraw(amount: token1Amount) as! @TeleportedSportiumToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- SprtUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- SprtUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtSprt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import SprtUsdtSwapPair from 0xSPRT_USDT_SWAP_PAIR_ADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let sprtVaultRef: &TeleportedSportiumToken.Vault
  let usdtTokenVaultRef: &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &SprtUsdtSwapPair.Vault

  prepare(signer: AuthAccount) {
    self.sprtVaultRef = signer.borrow<&TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.usdtTokenVaultRef = signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.borrow<&SprtUsdtSwapPair.Vault>(from: SprtUsdtSwapPair.TokenStoragePath) == nil {
      // Create a new flowToken Vault and put it in storage
      signer.save(<-SprtUsdtSwapPair.createEmptyVault(), to: SprtUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link <&SprtUsdtSwapPair.Vault{FungibleToken.Receiver}> (
        SprtUsdtSwapPair.TokenPublicReceiverPath,
        target: SprtUsdtSwapPair.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link <&SprtUsdtSwapPair.Vault{FungibleToken.Balance}> (
        SprtUsdtSwapPair.TokenPublicBalancePath,
        target: SprtUsdtSwapPair.TokenStoragePath
      )
    }

    self.liquidityTokenRef = signer.borrow<&SprtUsdtSwapPair.Vault>(from: SprtUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = SprtUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.sprtVaultRef.withdraw(amount: token1Amount) as! @TeleportedSportiumToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- SprtUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- SprtUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptAddFlowUsdt,
    REVV: scriptAddFlowRevv
  },
  tUSDT: {
    FLOW: scriptAddUsdtFlow,
    BLT: scriptAddUsdtBlt,
    SPRT: scriptAddUsdtSprt,
    STARLY: scriptAddUsdtStarly
  },
  BLT: {
    tUSDT: scriptAddBltUsdt
  },
  REVV: {
    FLOW: scriptAddRevvFlow
  },
  SPRT: {
    tUSDT: scriptAddSprtUsdt
  },
  STARLY: {
    tUSDT: scriptAddStarlyUsdt
  }
}

export default scripts

import { Currency } from '@uniswap/sdk'
import React from 'react'
import flowIcon from '../../assets/icon/tokens/colored/FLOW.svg'
import wflowIcon from '../../assets/images/wFLOW.png'
import usdtIcon from '../../assets/images/usdt.png'
import tusdtIcon from '../../assets/icon/tokens/colored/TUSDT.svg'
import fusdIcon from '../../assets/icon/tokens/colored/FUSD.svg'
import bltIcon from '../../assets/icon/tokens/colored/BLT.svg'
import starlyIcon from '../../assets/icon/tokens/colored/STARLY.svg'
import revvIcon from '../../assets/icon/tokens/colored/REVV.svg'
import iconsIcon from '../../assets/images/icons.png'
import usdcIcon from '../../assets/icon/tokens/colored/USDC.svg'
import sprtIcon from '../../assets/icon/tokens/colored/Sportium.svg'
import geniaceIcon from '../../assets/icon/tokens/colored/GENIACE.svg'
import moxyIcon from '../../assets/icon/tokens/colored/MOXY.svg'
import mynftIcon from '../../assets/icon/tokens/colored/MYNFT.svg'
import sportsIcon from '../../assets/icon/tokens/colored/SPORTSICON.svg'
import { TokenInfo, TokenInfoName } from '../../types'

import Logo from '../Logo'
import { Center } from '@chakra-ui/react'

const images: { [tokenName: string]: string } = {
  flow: flowIcon,
  wflow: wflowIcon,
  tusdt: tusdtIcon,
  usdt: usdtIcon,
  fusd: fusdIcon,
  blt: bltIcon,
  starly: starlyIcon,
  revv: revvIcon,
  icons: iconsIcon,
  usdc: usdcIcon,
  sprt: sprtIcon,
  geniace: geniaceIcon,
  moxy: moxyIcon,
  mynft: mynftIcon,
  sportsicon: sportsIcon
}

export default function CurrencyLogo({
  currency,
  size = '24px',
  placetop = false,
  style,
  ...rest
}: {
  currency?: TokenInfo | TokenInfoName | Currency
  size?: string
  placetop?: any
  style?: React.CSSProperties
}) {
  const iconSrc = images[currency?.symbol?.toLowerCase() ?? 'flow']
  return (
    <Center
      p="2px"
      bgColor="white"
      borderRadius="full"
      boxSize={size}
      alignSelf={placetop ? 'flex-start' : undefined}
      style={style}
      {...rest}
    >
      <Logo srcs={[iconSrc]} alt={`${currency?.symbol ?? 'token'} logo`} />
    </Center>
  )
}

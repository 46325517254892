import { useCallback } from 'react'
import { useFclReact } from '.'
import { replaceContractAddresses } from './env'
import useSwapRouter from './useSwapRouter'
import { PairDetail } from '../types'
import { ETHREUM_CHAIN_ID_CONFING } from '../connectors'
// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'

export const scriptBuilder = (route: Array<{ from: string; to: string; pair: PairDetail }>, isExactIn: boolean) => {
  const importsSnippet = route.map(({ pair }) => `import ${pair.name} from ${pair.address}`).join('\n')
  const declareQuoteSnippet = (isExactIn ? route : route.slice().reverse())
    .map(({ from, pair }, index) => {
      const { name, token0 } = pair
      const symbol = index === route.length - 1 ? 'quote' : `quote${index}`
      const amount = index ? `quote${index - 1}` : 'amount'
      const tokenFrom = `${isExactIn ? 'Exact' : ''}${token0.symbol === from ? 'Token1' : 'Token2'}`
      const tokenTo = `${isExactIn ? '' : 'Exact'}${token0.symbol === from ? 'Token2' : 'Token1'}`
      const swapMethod = `quoteSwap${tokenFrom}For${tokenTo}`
      return isExactIn
        ? `let ${symbol} = ${name}.${swapMethod}(amount: ${amount} * (1.0 - ${name}.getFeePercentage()))`
        : `let ${symbol} = ${name}.${swapMethod}(amount: ${amount}) / (1.0 - ${name}.getFeePercentage())`
    })
    .join('\n  ')

  const ammPairs = route
    // temporary fix for tUSDT <> FUSD
    .filter(
      ({ pair, from, to }) => !pair.noCharge && from !== 'FUSD' && to !== 'FUSD' && from !== 'USDC' && to !== 'USDC'
    )

  const getPoolAmountsSnippet = ammPairs
    .map(({ pair }, index) => `let poolAmounts${index} = ${pair.name}.getPoolAmounts()`)
    .join('\n  ')

  const currentPriceSnippet =
    ammPairs
      .map(({ pair, from }, index) => {
        const { token0 } = pair
        const poolAmounts = `poolAmounts${index}`
        const tokenFrom = token0.symbol === from ? 'token1' : 'token2'
        const tokenTo = token0.symbol === from ? 'token2' : 'token1'
        return `(${poolAmounts}.${tokenTo}Amount / ${poolAmounts}.${tokenFrom}Amount) * (1.0 - ${pair.name}.getFeePercentage())`
      })
      .join(' * ') || '1.0'

  return `\
${importsSnippet}

pub fun main(amount: UFix64): [UFix64] {
  ${declareQuoteSnippet}
  ${getPoolAmountsSnippet}
  let currentPrice = ${currentPriceSnippet}

  return [quote, currentPrice]
}
`
}

export default function useSwapQuote() {
  const { fcl, types } = useFclReact()
  const router = useSwapRouter()

  const createScript = useCallback((route, isExactIn) => {
    if (!route || !route.length) return null
    return scriptBuilder(route, isExactIn)
  }, [])

  const getSwapQuote = useCallback(
    async ({ currencyIn, currencyOut, amount, isExactIn }) => {
      const symbolIn = currencyIn?.symbol
      const symbolOut = currencyOut?.symbol
      if (!symbolIn || !symbolOut) throw new Error(`Invalid tokens.`)
      const routePlan = router.plan(symbolIn, symbolOut)
      let script = createScript(routePlan?.pathDetails, isExactIn)
      if (!script) throw new Error(`${symbolIn}-${symbolOut} pair does not exist.`)
      script = replaceContractAddresses(script, ETHREUM_CHAIN_ID_CONFING[NETWORK])

      const [rawQuote, rawCurrentPrice] = await fcl
        .send([fcl.script(script), fcl.args([fcl.arg(amount.toFixed(8), types.UFix64)])])
        .then(fcl.decode)
      const quote = parseFloat(rawQuote)
      const currentPrice = parseFloat(rawCurrentPrice)
      return { quote, currentPrice, route: routePlan?.path || [] }
    },
    [fcl, types, router, createScript]
  )

  return getSwapQuote
}

// // Wrong vault type, destroy it
// destroy signer.load<@AnyResource>(from: TeleportedTetherToken.TokenStoragePath)
// signer.unlink(TeleportedTetherToken.TokenPublicReceiverPath)
// signer.unlink(TeleportedTetherToken.TokenPublicBalancePath)

const scriptEnableUSDT = `\
// Add a Vault resource to user's account
// so that they can use the teleportedTetherToken (USDT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction {

  prepare(signer: AuthAccount) {
    if signer.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      signer.save(<-TeleportedTetherToken.createEmptyVault(), to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Receiver}>(
        TeleportedTetherToken.TokenPublicReceiverPath,
        target: TeleportedTetherToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&TeleportedTetherToken.Vault{FungibleToken.Balance}>(
        TeleportedTetherToken.TokenPublicBalancePath,
        target: TeleportedTetherToken.TokenStoragePath
      )
    }
  }
}
`

const scriptEnableFUSD = `\
// This transaction is a template for a transaction
// to add a Vault resource to their account
// so that they can use the FUSD

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS

transaction {

  prepare(signer: AuthAccount) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.borrow<&FUSD.Vault>(from: /storage/fusdVault) != nil) {
        return
    }
    
    // Create a new FUSD Vault and put it in storage
    signer.save(<-FUSD.createEmptyVault(), to: /storage/fusdVault)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    signer.link<&FUSD.Vault{FungibleToken.Receiver}>(
        /public/fusdReceiver,
        target: /storage/fusdVault
    )

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    signer.link<&FUSD.Vault{FungibleToken.Balance}>(
        /public/fusdBalance,
        target: /storage/fusdVault
    )
  }
}
`

const scriptEnableBLT = `\
// Add a Vault resource to user's account
// so that they can use the BloctoToken (BLT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS

transaction {

  prepare(signer: AuthAccount) {
    if signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      signer.save(<-BloctoToken.createEmptyVault(), to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&BloctoToken.Vault{FungibleToken.Receiver}>(
        BloctoToken.TokenPublicReceiverPath,
        target: BloctoToken.TokenStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&BloctoToken.Vault{FungibleToken.Balance}>(
        BloctoToken.TokenPublicBalancePath,
        target: BloctoToken.TokenStoragePath
      )
    }
  }
}
`

const scriptEnableFLOW = `\
transaction {
  prepare(signer: AuthAccount) {
  }
}
`

const scriptEnableSTARLY = `\
// Add a Vault resource to user's account
// so that they can use the StarlyToken (STARLY)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS

transaction {

  prepare(signer: AuthAccount) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath) != nil) {
      return
    }

    // Create a new Starly Token Vault and put it in storage
    signer.save(<-StarlyToken.createEmptyVault(), to: StarlyToken.TokenStoragePath)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    signer.link<&StarlyToken.Vault{FungibleToken.Receiver}>(
      StarlyToken.TokenPublicReceiverPath,
      target: StarlyToken.TokenStoragePath
    )

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    signer.link<&StarlyToken.Vault{FungibleToken.Balance}>(
      StarlyToken.TokenPublicBalancePath,
      target: StarlyToken.TokenStoragePath
    )
  }
}
`

const scriptEnableREVV = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS

transaction {

  prepare(signer: AuthAccount) {
    if signer.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath) == nil {
      signer.save(<-REVV.createEmptyVault(), to: REVV.RevvVaultStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&REVV.Vault{FungibleToken.Receiver}>(
        REVV.RevvReceiverPublicPath,
        target: REVV.RevvVaultStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&REVV.Vault{FungibleToken.Balance}>(
        REVV.RevvBalancePublicPath,
        target: REVV.RevvVaultStoragePath
      )
    }
  }
}
`

const scriptEnableICONS = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import IconsToken from 0xICONS_ADDRESS

transaction {

  prepare(signer: AuthAccount) {
    if signer.borrow<&IconsToken.Vault>(from: IconsToken.VaultStoragePath) == nil {
      signer.save(<-IconsToken.createEmptyVault(), to: IconsToken.VaultStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&IconsToken.Vault{FungibleToken.Receiver}>(
        IconsToken.VaultReceiverPublicPath,
        target: IconsToken.VaultStoragePath
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&IconsToken.Vault{FungibleToken.Balance}>(
        IconsToken.VaultBalancePublicPath,
        target: IconsToken.VaultStoragePath
      )
    }
  }
}
`

const scriptEnableUSDC = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FiatToken from 0xUSDC_TOKEN_ADDRESS

transaction {

  prepare(signer: AuthAccount) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath) != nil) {
      return
    }
    
    // Create a new Blocto Token Vault and put it in storage
    signer.save(<-FiatToken.createEmptyVault(), to: FiatToken.VaultStoragePath)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    signer.link<&FiatToken.Vault{FungibleToken.Receiver}>(
      FiatToken.VaultReceiverPubPath,
      target: FiatToken.VaultStoragePath
    )

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    signer.link<&FiatToken.Vault{FungibleToken.Balance}>(
      FiatToken.VaultBalancePubPath,
      target: FiatToken.VaultStoragePath
    )
  }
}
`
const scriptEnableSPRT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

transaction {

  prepare(signer: AuthAccount) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.borrow<&TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath) != nil) {
      return
    }
    
    // Create a new Blocto Token Vault and put it in storage
    signer.save(<-TeleportedSportiumToken.createEmptyVault(), to: TeleportedSportiumToken.TokenStoragePath)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    signer.link<&TeleportedSportiumToken.Vault{FungibleToken.Receiver}>(
      TeleportedSportiumToken.TokenPublicReceiverPath,
      target: TeleportedSportiumToken.TokenStoragePath
    )

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    signer.link<&TeleportedSportiumToken.Vault{FungibleToken.Balance}>(
      TeleportedSportiumToken.TokenPublicBalancePath,
      target: TeleportedSportiumToken.TokenStoragePath
    )
  }
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptEnableUSDT,
  FUSD: scriptEnableFUSD,
  BLT: scriptEnableBLT,
  FLOW: scriptEnableFLOW,
  STARLY: scriptEnableSTARLY,
  REVV: scriptEnableREVV,
  ICONS: scriptEnableICONS,
  USDC: scriptEnableUSDC,
  SPRT: scriptEnableSPRT
}

export default scripts

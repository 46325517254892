export enum Network {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  FLOW = 'FLOW',
  SOLANA = 'SOLANA',
  APTOS = 'APTOS'
}

export interface TokenInfo {
  readonly network: Network
  readonly chainId: number
  readonly address: string
  readonly name: string
  readonly decimals: number
  readonly symbol: string
  readonly logoURI?: string
  readonly tags?: string[]
  readonly coreSymbol?: string
}

export interface TokenInfoName {
  readonly name: string
  readonly symbol: string
  readonly networks?: string[]
}

export interface FlowTokenMetadata {
  readonly name: string
  readonly displayName: string
  readonly symbol: string
  readonly address: string
  readonly vaultPath: string
  readonly receiverPath?: string
  readonly balancePath?: string
}

export interface Pair {
  readonly liquidityToken?: TokenInfo
  readonly token0: TokenInfo
  readonly token1: TokenInfo
  readonly price?: number
}

export interface PairDetail extends Pair {
  readonly name: string
  readonly address: string
  // @todo: remove this if there're no no-charge pairs like FUSD/tUSDT in the future
  readonly noCharge?: boolean
}

export interface Price {
  readonly price?: number
  readonly baseCurrency?: TokenInfo
  readonly quoteCurrency?: TokenInfo
}

export interface TransactionResponse {
  transactionId?: string
  hash?: string
  statusCode?: number
}

export interface TransactionReceipt {
  transactionHash: string
  status?: number
  statusCode?: number
}

export interface TransactionAbstract {
  hash: string
  receipt?: TransactionReceipt
  addedTime: number
  network: Network
}

export enum StepState {
  unactive = 'unactive',
  ongoing = 'ongoing',
  passed = 'passed',
  failed = 'failed'
}
export enum StepID {
  kyc = 'kyc',
  pool = 'pool',
  deposit = 'deposit',
  complete = 'complete'
}

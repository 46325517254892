const script = `\
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS)
    .getCapability(/public/teleportedTetherTokenTeleportUser)
    .borrow<&TeleportedTetherToken.TeleportAdmin{TeleportedTetherToken.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptSPLBLT = `\
import TeleportCustodySolana from 0xTELEPORTSOLANAADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptBEP20BLT = `\
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`
const scriptETHEREUMBLT = `\
import TeleportCustodyEthereum from 0xTELEPORT_ETHEREUM_BLT_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyEthereum.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptAPTOSBLT = `\
import TeleportCustodyAptos from 0xTELEPORTAPTOSADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyAptos.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyAptos.TeleportAdmin{TeleportCustodyAptos.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyAptos.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptSPLMOXY = `\
import TeleportCustodySolana from 0xTELEPORT_MOXY_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptBEP20MOXY = `\
import TeleportCustodyBSC from 0xTELEPORT_MOXY_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptERC20MOXY = `\
import TeleportCustodyEthereum from 0xTELEPORT_MOXY_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodyEthereum.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptWFLOW = `\
import TeleportCustody from 0xTELEPORTWFLOWADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTWFLOWADMINADDRESS)
      .getCapability(TeleportCustody.TeleportUserPublicPath)!
      .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustody.TeleportUser")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptSTARLYBSC = `\
import TeleportCustodyBSC from 0xTELEPORTSTARLYADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTSTARLYADMINADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>()
       ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptREVV = `\
import TeleportCustody from 0xREVVTELEPORTCUSTODYADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTREVVADMINADDRESS)
    .getCapability(TeleportCustody.TeleportUserPublicPath)
    .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptICONS = `\
import TeleportCustody from 0xICONS_TELEPORT_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_ICONS_ADMIN_ADDRESS)
    .getCapability(TeleportCustody.TeleportUserPublicPath)
    .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptUSDC = `\
import TeleportCustodyEthereum from 0xUSDC_TELEPORT_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xUSDC_TELEPORT_ADMIN_ADDRESS)
    .getCapability(TeleportCustodyEthereum.TeleportUserPublicPath)
    .borrow<&TeleportCustodyEthereum.TeleportAdmin{TeleportCustodyEthereum.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptSPRT = `\
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

// This script reads the Vault balances of two accounts.
pub fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_SPRT_ADMIN_ADDRESS)
    .getCapability(/public/TeleportedSportiumTokenTeleportUser)
    .borrow<&TeleportedSportiumToken.TeleportAdmin{TeleportedSportiumToken.TeleportUser}>()
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const sciprts: { [token1Name: string]: { [token2Name: string]: string } } = {
  USDT_ETHEREUM: {
    // eslint-disable-next-line
    tUSDT_FLOW: script
  },
  // eslint-disable-next-line
  tUSDT_FLOW: {
    USDT_ETHEREUM: script
  },
  // eslint-disable-next-line
  wFLOW_ETHEREUM: {
    // eslint-disable-next-line
    FLOW_FLOW: scriptWFLOW
  },
  // eslint-disable-next-line
  FLOW_FLOW: {
    // eslint-disable-next-line
    wFLOW_ETHEREUM: scriptWFLOW
  },
  BLT_FLOW: {
    BLT_SOLANA: scriptSPLBLT,
    BLT_BSC: scriptBEP20BLT,
    BLT_APTOS: scriptAPTOSBLT,
    BLT_ETHEREUM: scriptETHEREUMBLT
  },
  BLT_SOLANA: {
    BLT_FLOW: scriptSPLBLT
  },
  BLT_BSC: {
    BLT_FLOW: scriptBEP20BLT
  },
  BLT_APTOS: {
    BLT_FLOW: scriptAPTOSBLT
  },
  BLT_ETHEREUM: {
    BLT_FLOW: scriptETHEREUMBLT
  },
  MOXY_FLOW: {
    MOXY_SOLANA: scriptSPLMOXY,
    MOXY_BSC: scriptBEP20MOXY,
    MOXY_ETHEREUM: scriptERC20MOXY
  },
  MOXY_SOLANA: {
    MOXY_FLOW: scriptSPLMOXY
  },
  MOXY_BSC: {
    MOXY_FLOW: scriptBEP20MOXY
  },
  MOXY_ETHEREUM: {
    MOXY_FLOW: scriptERC20MOXY
  },
  STARLY_FLOW: {
    STARLY_BSC: scriptSTARLYBSC
  },
  STARLY_BSC: {
    STARLY_FLOW: scriptSTARLYBSC
  },
  REVV_FLOW: {
    REVV_ETHEREUM: scriptREVV
  },
  REVV_ETHEREUM: {
    REVV_FLOW: scriptREVV
  },
  ICONS_FLOW: {
    ICONS_BSC: scriptICONS
  },
  ICONS_BSC: {
    ICONS_FLOW: scriptICONS
  },
  USDC_FLOW: {
    USDC_ETHEREUM: scriptUSDC
  },
  USDC_ETHEREUM: {
    USDC_FLOW: scriptUSDC
  },
  SPRT_ETHEREUM: {
    SPRT_FLOW: scriptSPRT
  },
  SPRT_FLOW: {
    SPRT_ETHEREUM: scriptSPRT
  }
}

export default sciprts

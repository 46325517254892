import { ChainId } from '@uniswap/sdk'

enum ContractId {
  BLOCTO_TOKEN_ADDRESS = 'BLOCTO_TOKEN_ADDRESS',
  BLOCTO_SWAP_LISTING_ADDRESS = 'BLOCTO_SWAP_LISTING_ADDRESS',
  BLT_USDT_SWAP_PAIR_ADDRESS = 'BLT_USDT_SWAP_PAIR_ADDRESS',
  FLOW_STORAGE_FEES_ADDRESS = 'FLOW_STORAGE_FEES_ADDRESS',
  FLOW_SWAP_PAIR_ADDRESS = 'FLOW_SWAP_PAIR_ADDRESS',
  FLOW_TOKEN_ADDRESS = 'FLOW_TOKEN_ADDRESS',
  FLOW_USD_ADDRESS = 'FLOW_USD_ADDRESS',
  FUNGIBLE_TOKEN_ADDRESS = 'FUNGIBLE_TOKEN_ADDRESS',
  FUSD_USDT_SWAP_PAIR_ADDRESS = 'FUSD_USDT_SWAP_PAIR_ADDRESS',
  ICONS_TOKEN_ADDRESS = 'ICONS_TOKEN_ADDRESS',
  ICONS_TELEPORT_CUSTODY_ADDRESS = 'ICONS_TELEPORT_CUSTODY_ADDRESS',
  IDO_ADDRESS = 'IDO_ADDRESS',
  MOXY_TOKEN_ADDRESS = 'MOXY_TOKEN_ADDRESS',
  NON_FUNGIBLE_TOKEN_ADDRESS = 'NON_FUNGIBLE_TOKEN_ADDRESS',
  REVV_FLOW_SWAP_PAIR_ADDRESS = 'REVV_FLOW_SWAP_PAIR_ADDRESS',
  REVV_TOKEN_ADDRESS = 'REVV_TOKEN_ADDRESS',
  REVV_TELEPORT_CUSTODY_ADDRESS = 'REVV_TELEPORT_CUSTODY_ADDRESS',
  SPRT_USDT_SWAP_PAIR_ADDRESS = 'SPRT_USDT_SWAP_PAIR_ADDRESS',
  STARLY_TOKEN_ADDRESS = 'STARLY_TOKEN_ADDRESS',
  STARLY_USDT_SWAP_PAIR_ADDRESS = 'STARLY_USDT_SWAP_PAIR_ADDRESS',
  TELEPORT_BLT_ADMIN_ADDRESS = 'TELEPORT_BLT_ADMIN_ADDRESS',
  TELEPORT_BSC_BLT_ADDRESS = 'TELEPORT_BSC_BLT_ADDRESS',
  TELEPORT_ETHEREUM_BLT_ADDRESS = 'TELEPORT_ETHEREUM_BLT_ADDRESS',
  TELEPORT_APTOS_BLT_ADDRESS = 'TELEPORT_APTOS_BLT_ADDRESS',
  TELEPORT_ICONS_ADMIN_ADDRESS = 'TELEPORT_ICONS_ADMIN_ADDRESS',
  TELEPORT_MOXY_ADDRESS = 'TELEPORT_MOXY_ADDRESS',
  TELEPORT_MOXY_ADMIN_ADDRESS = 'TELEPORT_MOXY_ADMIN_ADDRESS',
  TELEPORT_SOLANA_ADDRESS = 'TELEPORT_SOLANA_ADDRESS',
  TELEPORT_REVV_ADMIN_ADDRESS = 'TELEPORT_REVV_ADMIN_ADDRESS',
  TELEPORT_SPRT_ADMIN_ADDRESS = 'TELEPORT_SPRT_ADMIN_ADDRESS',
  TELEPORT_STARLY_ADDRESS = 'TELEPORT_STARLY_ADDRESS',
  TELEPORT_STARLY_ADMIN_ADDRESS = 'TELEPORT_STARLY_ADMIN_ADDRESS',
  TELEPORT_USDT_ADMIN_ADDRESS = 'TELEPORT_USDT_ADMIN_ADDRESS',
  TELEPORT_WFLOW_ADDRESS = 'TELEPORT_WFLOW_ADDRESS',
  TELEPORT_WFLOW_ADMIN_ADDRESS = 'TELEPORT_WFLOW_ADMIN_ADDRESS',
  TELEPORTED_SPRT_ADDRESS = 'TELEPORTED_SPRT_ADDRESS',
  TELEPORTED_USDT_ADDRESS = 'TELEPORTED_USDT_ADDRESS',
  TOKEN_POOL_ADDRESS = 'TOKEN_POOL_ADDRESS',
  USDC_TELEPORT_ADDRESS = 'USDC_TELEPORT_ADDRESS',
  USDC_TELEPORT_ADMIN_ADDRESS = 'USDC_TELEPORT_ADMIN_ADDRESS',
  USDC_TOKEN_ADDRESS = 'USDC_TOKEN_ADDRESS',
  USDC_USDT_SWAP_PAIR_ADDRESS = 'USDC_USDT_SWAP_PAIR_ADDRESS'
}

type ContractAddressList = {
  readonly [contractId in ContractId]: string
}

const FAKE_ADDRESSES: ContractAddressList = {
  [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
  [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0x217b60138188a81d',
  [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xfcb06a5ae5b21a2d',
  [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
  [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0x3d502e29b7076e11',
  [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
  [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
  [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
  [ContractId.ICONS_TOKEN_ADDRESS]: '0xf446a5994b73b5f3',
  [ContractId.ICONS_TELEPORT_CUSTODY_ADDRESS]: '0x47ca267fdd17ecde',
  [ContractId.IDO_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.MOXY_TOKEN_ADDRESS]: '0x678a363d069ccfe8',
  [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0xd017f81bffc9aa05',
  [ContractId.REVV_TOKEN_ADDRESS]: '0x14ca72fa4d45d2c3',
  [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0xe9008c00f410c92b',
  [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x0f5be7fc963db41b',
  [ContractId.STARLY_TOKEN_ADDRESS]: '0xf63219072aaddd50',
  [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x22d84efc93a8b21a',
  [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0xf086a545ce3c552d',
  [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x967a0fb3c949cbc5',
  [ContractId.TELEPORT_ICONS_ADMIN_ADDRESS]: '0x809c50b5b766a4de',
  [ContractId.TELEPORT_MOXY_ADDRESS]: '0x3a6181d72095f4d4',
  [ContractId.TELEPORT_MOXY_ADMIN_ADDRESS]: '0x829c38b3704d33b9',
  [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0x205d4db4d4592a73',
  [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0xb097866d87d01550',
  [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_STARLY_ADDRESS]: '0xe0667c34b3a9d731',
  [ContractId.TELEPORT_STARLY_ADMIN_ADDRESS]: '0xefb1c4923933f4b5',
  [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xceb10c84a8a7f414',
  [ContractId.TELEPORT_WFLOW_ADDRESS]: '0x1d8fbaa8a5dae083',
  [ContractId.TELEPORT_WFLOW_ADMIN_ADDRESS]: '0xefb1c4923933f4b5',
  [ContractId.TELEPORTED_SPRT_ADDRESS]: '0xb097866d87d01550',
  [ContractId.TELEPORTED_USDT_ADDRESS]: '0xab26e0a07d770ec1',
  [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
  [ContractId.USDC_TELEPORT_ADDRESS]: '0x27cac7970c66e966',
  [ContractId.USDC_TELEPORT_ADMIN_ADDRESS]: '0xa2e3b76c3c80866c',
  [ContractId.USDC_TOKEN_ADDRESS]: '0xa983fecbed621163',
  [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x481744401ea249c0'
}

const CONTRACT_ADDRESSES: { [chainId in ChainId]: ContractAddressList } = {
  [ChainId.MAINNET]: {
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
    [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0x923f5c3c39a7649d',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xfcb06a5ae5b21a2d',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0xe467b9dd11fa00df',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xc6c77b9f5c7a378f',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
    [ContractId.FLOW_USD_ADDRESS]: '0x3c5959b568896393',
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
    [ContractId.ICONS_TOKEN_ADDRESS]: '0xf446a5994b73b5f3',
    [ContractId.ICONS_TELEPORT_CUSTODY_ADDRESS]: '0x47ca267fdd17ecde',
    [ContractId.IDO_ADDRESS]: '0x49fc55f59a2d1703',
    [ContractId.MOXY_TOKEN_ADDRESS]: '0x678a363d069ccfe8',
    [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0x1d7e57aa55817448',
    [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0x5e284fb7cff23a3f',
    [ContractId.REVV_TOKEN_ADDRESS]: '0xd01e482eb680ec9f',
    [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0x04ee69443dedf0e4',
    [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x60e1db11f37ac469',
    [ContractId.STARLY_TOKEN_ADDRESS]: '0x142fa6570b62fd97',
    [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x6efab66df92c37e4',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x73d494fc6fe4b127',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x714000cf4dd1c4ed',
    [ContractId.TELEPORT_ICONS_ADMIN_ADDRESS]: '0x809c50b5b766a4de',
    [ContractId.TELEPORT_MOXY_ADDRESS]: '0x3a6181d72095f4d4',
    [ContractId.TELEPORT_MOXY_ADMIN_ADDRESS]: '0x829c38b3704d33b9',
    [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0x08a13c66a11dea60',
    [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0xdcad421ef2d27e07',
    [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_STARLY_ADDRESS]: '0xc2fa71c36fd5b840',
    [ContractId.TELEPORT_STARLY_ADMIN_ADDRESS]: '0x8c0832edb0aef3aa',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0x78fea665a361cf0e',
    [ContractId.TELEPORT_WFLOW_ADDRESS]: '0xbd7e596b12e277df',
    [ContractId.TELEPORT_WFLOW_ADMIN_ADDRESS]: '0x687cd710bfb80892',
    [ContractId.TELEPORTED_SPRT_ADDRESS]: '0x475755d2c9dccc3a',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xcfdd90d4a00f7b5b',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x08f9e2ed8b0d548a',
    [ContractId.USDC_TELEPORT_ADDRESS]: '0xaad831d03d67904c',
    [ContractId.USDC_TELEPORT_ADMIN_ADDRESS]: '0xd86f14ad249f828a',
    [ContractId.USDC_TOKEN_ADDRESS]: '0xb19436aae4d94622',
    [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x9c6f94adf47904b5'
  },
  [ChainId.RINKEBY]: {
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x6e0797ac987005f5',
    [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xc59604d4e65f14b3',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x7e60df042a9c0868',
    [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0x9a0766d93b6608b7',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x3502a5dacaf350bb',
    [ContractId.ICONS_TOKEN_ADDRESS]: '0xf446a5994b73b5f3',
    [ContractId.ICONS_TELEPORT_CUSTODY_ADDRESS]: '0x47ca267fdd17ecde',
    [ContractId.IDO_ADDRESS]: '0xa2d8020d03e30815',
    [ContractId.MOXY_TOKEN_ADDRESS]: '0x678a363d069ccfe8',
    [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0x631e88ae7f1d7c20',
    [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0xd017f81bffc9aa05',
    [ContractId.REVV_TOKEN_ADDRESS]: '0x14ca72fa4d45d2c3',
    [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0xe9008c00f410c92b',
    [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x0f5be7fc963db41b',
    [ContractId.STARLY_TOKEN_ADDRESS]: '0xf63219072aaddd50',
    [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x22d84efc93a8b21a',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x83cbadbe67c9ac0c',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0f8d5673af37f9e6',
    [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_ICONS_ADMIN_ADDRESS]: '0x809c50b5b766a4de',
    [ContractId.TELEPORT_MOXY_ADDRESS]: '0x3a6181d72095f4d4',
    [ContractId.TELEPORT_MOXY_ADMIN_ADDRESS]: '0x829c38b3704d33b9',
    [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0xf086a545ce3c552d',
    [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0x6d68fb7db988aea8',
    [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_STARLY_ADDRESS]: '0xe0667c34b3a9d731',
    [ContractId.TELEPORT_STARLY_ADMIN_ADDRESS]: '0x4a1cc71a0a845e5b',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xceb10c84a8a7f414',
    [ContractId.TELEPORT_WFLOW_ADDRESS]: '0x1d8fbaa8a5dae083',
    [ContractId.TELEPORT_WFLOW_ADMIN_ADDRESS]: '0xefb1c4923933f4b5',
    [ContractId.TELEPORTED_SPRT_ADDRESS]: '0xb097866d87d01550',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xab26e0a07d770ec1',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
    [ContractId.USDC_TELEPORT_ADDRESS]: '0x27cac7970c66e966',
    [ContractId.USDC_TELEPORT_ADMIN_ADDRESS]: '0xa2e3b76c3c80866c',
    [ContractId.USDC_TOKEN_ADDRESS]: '0xa983fecbed621163',
    [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x481744401ea249c0'
  },
  [ChainId.GÖRLI]: {
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x6e0797ac987005f5',
    [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xc59604d4e65f14b3',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x7e60df042a9c0868',
    [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0x9a0766d93b6608b7',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x3502a5dacaf350bb',
    [ContractId.ICONS_TOKEN_ADDRESS]: '0xf446a5994b73b5f3',
    [ContractId.ICONS_TELEPORT_CUSTODY_ADDRESS]: '0x47ca267fdd17ecde',
    [ContractId.IDO_ADDRESS]: '0xa2d8020d03e30815',
    [ContractId.MOXY_TOKEN_ADDRESS]: '0x678a363d069ccfe8',
    [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0x631e88ae7f1d7c20',
    [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0xd017f81bffc9aa05',
    [ContractId.REVV_TOKEN_ADDRESS]: '0x14ca72fa4d45d2c3',
    [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0xe9008c00f410c92b',
    [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x0f5be7fc963db41b',
    [ContractId.STARLY_TOKEN_ADDRESS]: '0xf63219072aaddd50',
    [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x22d84efc93a8b21a',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x83cbadbe67c9ac0c',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0f8d5673af37f9e6',
    [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_ICONS_ADMIN_ADDRESS]: '0x809c50b5b766a4de',
    [ContractId.TELEPORT_MOXY_ADDRESS]: '0x3a6181d72095f4d4',
    [ContractId.TELEPORT_MOXY_ADMIN_ADDRESS]: '0x829c38b3704d33b9',
    [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0xf086a545ce3c552d',
    [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0x6d68fb7db988aea8',
    [ContractId.TELEPORT_SOLANA_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_STARLY_ADDRESS]: '0xe0667c34b3a9d731',
    [ContractId.TELEPORT_STARLY_ADMIN_ADDRESS]: '0x4a1cc71a0a845e5b',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xceb10c84a8a7f414',
    [ContractId.TELEPORT_WFLOW_ADDRESS]: '0x1d8fbaa8a5dae083',
    [ContractId.TELEPORT_WFLOW_ADMIN_ADDRESS]: '0xefb1c4923933f4b5',
    [ContractId.TELEPORTED_SPRT_ADDRESS]: '0xb097866d87d01550',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xab26e0a07d770ec1',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
    [ContractId.USDC_TELEPORT_ADDRESS]: '0x27cac7970c66e966',
    [ContractId.USDC_TELEPORT_ADMIN_ADDRESS]: '0xa2e3b76c3c80866c',
    [ContractId.USDC_TOKEN_ADDRESS]: '0xa983fecbed621163',
    [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x481744401ea249c0'
  },
  [ChainId.KOVAN]: FAKE_ADDRESSES,
  [ChainId.ROPSTEN]: FAKE_ADDRESSES
}

export const replaceContractAddresses = (script: string, env: ChainId): string => {
  if (!script) {
    return script
  }

  const addresses = CONTRACT_ADDRESSES[env] || {}

  return script
    .replaceAll('0xBLTADDRESS', addresses[ContractId.BLOCTO_TOKEN_ADDRESS])
    .replaceAll('0xBLOCTO_SWAP_LISING_ADDRESS', addresses[ContractId.BLOCTO_SWAP_LISTING_ADDRESS])
    .replaceAll('0xBLTUSDTSWAPPAIRADDRESS', addresses[ContractId.BLT_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xFLOWSTORAGEFEESADDRESS', addresses[ContractId.FLOW_STORAGE_FEES_ADDRESS])
    .replaceAll('0xFLOWSWAPPAIRADDRESS', addresses[ContractId.FLOW_SWAP_PAIR_ADDRESS])
    .replaceAll('0xFLOWTOKENADDRESS', addresses[ContractId.FLOW_TOKEN_ADDRESS])
    .replaceAll('0xFUNGIBLETOKENADDRESS', addresses[ContractId.FUNGIBLE_TOKEN_ADDRESS])
    .replaceAll('0xFUSDADDRESS', addresses[ContractId.FLOW_USD_ADDRESS])
    .replaceAll('0xFUSDUSDTSWAPPAIRADDRESS', addresses[ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xICONS_ADDRESS', addresses[ContractId.ICONS_TOKEN_ADDRESS])
    .replaceAll('0xICONS_TELEPORT_ADDRESS', addresses[ContractId.ICONS_TELEPORT_CUSTODY_ADDRESS])
    .replaceAll('0xIDOADDRESS', addresses[ContractId.IDO_ADDRESS])
    .replaceAll('0xMOXY_TOKEN_ADDRESS', addresses[ContractId.MOXY_TOKEN_ADDRESS])
    .replaceAll('0xNONFUNGIBLETOKENADDRESS', addresses[ContractId.NON_FUNGIBLE_TOKEN_ADDRESS])
    .replaceAll('0xREVV_FLOW_SWAP_PAIR_ADDRESS', addresses[ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS])
    .replaceAll('0xREVVTOKENADDRESS', addresses[ContractId.REVV_TOKEN_ADDRESS])
    .replaceAll('0xREVVTELEPORTCUSTODYADDRESS', addresses[ContractId.REVV_TELEPORT_CUSTODY_ADDRESS])
    .replaceAll('0xSPRT_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xSTARLYTOKENADDRESS', addresses[ContractId.STARLY_TOKEN_ADDRESS])
    .replaceAll('0xSTARLY_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xTELEPORTBLTADMINADDRESS', addresses[ContractId.TELEPORT_BLT_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTBSCBLTADDRESS', addresses[ContractId.TELEPORT_BSC_BLT_ADDRESS])
    .replaceAll('0xTELEPORT_ETHEREUM_BLT_ADDRESS', addresses[ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS])
    .replaceAll('0xTELEPORTAPTOSBLTADDRESS', addresses[ContractId.TELEPORT_APTOS_BLT_ADDRESS])
    .replaceAll('0xTELEPORTEDUSDTADDRESS', addresses[ContractId.TELEPORTED_USDT_ADDRESS])
    .replaceAll('0xTELEPORT_ICONS_ADMIN_ADDRESS', addresses[ContractId.TELEPORT_ICONS_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORT_MOXY_ADDRESS', addresses[ContractId.TELEPORT_MOXY_ADDRESS])
    .replaceAll('0xTELEPORT_MOXY_ADMIN_ADDRESS', addresses[ContractId.TELEPORT_MOXY_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTREVVADMINADDRESS', addresses[ContractId.TELEPORT_REVV_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTSOLANAADDRESS', addresses[ContractId.TELEPORT_SOLANA_ADDRESS])
    .replaceAll('0xTELEPORTAPTOSADDRESS', addresses[ContractId.TELEPORT_APTOS_BLT_ADDRESS])
    .replaceAll('0xTELEPORTSTARLYADDRESS', addresses[ContractId.TELEPORT_STARLY_ADDRESS])
    .replaceAll('0xTELEPORTSTARLYADMINADDRESS', addresses[ContractId.TELEPORT_STARLY_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTUSDTADMINADDRESS', addresses[ContractId.TELEPORT_USDT_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTWFLOWADDRESS', addresses[ContractId.TELEPORT_WFLOW_ADDRESS])
    .replaceAll('0xTELEPORTWFLOWADMINADDRESS', addresses[ContractId.TELEPORT_WFLOW_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTED_SPRT_ADDRESS', addresses[ContractId.TELEPORTED_SPRT_ADDRESS])
    .replaceAll('0xTELEPORT_SPRT_ADMIN_ADDRESS', addresses[ContractId.TELEPORT_SPRT_ADMIN_ADDRESS])
    .replaceAll('0xTOKENPOOLADDRESS', addresses[ContractId.TOKEN_POOL_ADDRESS])
    .replaceAll('0xUSDC_TELEPORT_ADDRESS', addresses[ContractId.USDC_TELEPORT_ADDRESS])
    .replaceAll('0xUSDC_TELEPORT_ADMIN_ADDRESS', addresses[ContractId.USDC_TELEPORT_ADMIN_ADDRESS])
    .replaceAll('0xUSDC_TOKEN_ADDRESS', addresses[ContractId.USDC_TOKEN_ADDRESS])
    .replaceAll('0xUSDC_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.USDC_USDT_SWAP_PAIR_ADDRESS])
}

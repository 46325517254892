import { PublicKey } from '@solana/web3.js'

export const WALLET_PROGRAM = new PublicKey('D6vhDDD47LqHfHGps5YKkzJNBhXasrTf5LbNmkF3XHww')

// BLT
export const BLT_FLOW_TELEPORT_PROGRAM = new PublicKey('BLT2FgFauegpkQPXmT9dSKtHevX1dDNPgj4KtKFiDbPq')
export const BLT_FLOW_TELEPORT_CONFIG = new PublicKey('BuJzFW6BrdoXurz96a8KtbHZ3ykEeuyEbBNeBCTzMaot')
export const BLT_FLOW_TELEPORT_SIGNER = new PublicKey('ERhaf1homTHUFCjvc8iyFTQYnHfXVvm5hTMCcQp48jE3')
export const BLT_TOKEN_MINT = new PublicKey('BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef')
export const BLT_WALLET_ACCOUNT = new PublicKey('6A1yQozCjcwo6dmbNRzGMxc6KLMe5wqEvLLj5BBE4h8f')
export const BLT_WALLET_ACCOUNT_PDA = new PublicKey('6NXwQG4fqdZKSKkUmCK11ob2kvx1HqYQfqtwNoKRwRgZ')

// MOXY
export const MOXY_FLOW_TELEPORT_PROGRAM = new PublicKey('Moxy64HPS9cfAqS3eVHxWh9bW1ytA2cpVeLh63eSxcT')
export const MOXY_FLOW_TELEPORT_CONFIG = new PublicKey('H7zXqf12KbRA3rye6xVEgoxUXbnboPaoonKeN6Y4TV3A')
export const MOXY_FLOW_TELEPORT_SIGNER = new PublicKey('7FRMWQbT8Q3qucwo16LirPqLpEmmv1mxSEW9CvTcf6q9')
export const MOXY_TOKEN_MINT = new PublicKey('MoxyTCL98nrDm5DjWfh1Re4e8vvPQttqLAkJg3ATYpQ')
export const MOXY_WALLET_ACCOUNT = new PublicKey('GQTMuSgGaJMp6iEUMWyyWKUGRhaBXcpipA4pEo6467ii')
export const MOXY_WALLET_ACCOUNT_PDA = new PublicKey('86YSyXsTfzVzmHRuHpjUn67JgyUFiA1PmVJEttrrW56h')

import React, { useMemo } from 'react'
import { Trade } from '../../fcl-react'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import CurrencyLogo from '../CurrencyLogo'
import ArrowDownIcon from '../../assets/icon/view/arrow_down.svg'
import { Flex, Box, Center, Image } from '@chakra-ui/react'
import { Network } from '../../types'
import { useWeb3React } from '@web3-react/core'
import { SUPPORTED_WALLETS, currentEnv } from '../../constants'
import { injected } from '../../connectors'
import { useFclReact } from '../../fcl-react'
import { useSolana } from '../../solana-react/useSolana'
import { useAptos } from '../../aptos-react/useAptos'
import NetworkLogo from '../NetworkLogo'
import { shortenAddress, shortenEthereumAddress, shortenSolanaAddress } from '../../utils'
import { ReactComponent as AlertIcon } from '../../assets/icon/status/alert_outline.svg'
import { useTranslation } from 'react-i18next'

function useConnectorName() {
  const { chainId, connector, account: ethAccount } = useWeb3React()
  const { account: flowAccount } = useFclReact()
  const { account: solanaAccount } = useSolana()
  const { account: aptosAccount } = useAptos()
  function formatConnectorName() {
    const { ethereum } = window

    const isBlocto = !!(ethereum && ethereum.isBlocto)
    if (isBlocto) {
      return 'Blocto'
    }
    // if (!!(ethereum && ethereum?.isMetaMask)) {
    //   return 'MetaMask'
    // }

    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k => SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isBlocto === (k === 'BLOCTO'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return name
  }
  return {
    [Network.FLOW]: { account: shortenAddress(flowAccount || ''), connector: 'Blocto', env: currentEnv },
    [Network.SOLANA]: {
      account: shortenSolanaAddress(solanaAccount),
      connector: 'Blocto',
      env: currentEnv === 'mainnet' ? 'mainnet' : 'devnet'
    },
    [Network.BSC]: {
      account: shortenEthereumAddress(ethAccount || ''),
      connector: formatConnectorName(),
      env: chainId === 56 ? 'BSC mainnet' : 'BSC testnet'
    },
    [Network.ETHEREUM]: {
      account: shortenEthereumAddress(ethAccount || ''),
      connector: formatConnectorName(),
      env: chainId === 1 ? 'mainnet' : 'testnet'
    },
    [Network.APTOS]: {
      account: shortenSolanaAddress(aptosAccount || ''),
      connector: 'Blocto',
      env: currentEnv === 'mainnet' ? 'mainnet' : 'testnet'
    }
  }
}

export default function TeleportModalHeader({ trade }: { trade: Trade }) {
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)
  const connectorName = useConnectorName()
  const { t } = useTranslation()

  return (
    <Box>
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column" width="100%" bgColor="background.tertiary" p="16px" borderRadius="12px">
          <Flex mb="12px" alignItems="center" justifyContent="space-between">
            <Box fontWeight={600} fontSize="24px">
              {trade.inputAmount.toString()}
            </Box>
            <Center fontWeight={500}>
              <CurrencyLogo currency={trade.inputCurrency} size={'28px'} />
              <Flex flexDirection="column" ml="8px" alignItems="flex-start">
                <Box fontSize="14px">{trade.inputCurrency.symbol}</Box>
                <Box color="font.secondary" fontSize="12px" fontWeight="400">
                  {trade.inputCurrency.network}
                </Box>
              </Flex>
            </Center>
          </Flex>
          <Center
            pt="12px"
            justifyContent="space-between"
            color="font.secondary"
            borderTop="1px solid"
            borderColor="border.secondary"
          >
            <Box fontSize="12px">{t('teleport.modal.From')}</Box>

            <Flex fontSize="12px" color="font.secondary">
              <NetworkLogo network={trade.inputCurrency.network} size={14} />
              <Box ml="4px" mr="8px">
                {connectorName[trade.inputCurrency.network].account}
              </Box>
              {connectorName[trade.inputCurrency.network].connector} | {connectorName[trade.inputCurrency.network].env}
            </Flex>
          </Center>
        </Flex>
        <Center
          mt="-12px"
          mb="-24px"
          boxSize="36px"
          borderRadius="full"
          bgColor="white"
          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
          zIndex="100"
        >
          <Image src={ArrowDownIcon} boxSize="20px" />
        </Center>
        <Flex mt="12px" flexDirection="column" width="100%" bgColor="background.tertiary" p="16px" borderRadius="12px">
          <Flex mb="12px" alignItems="center" justifyContent="space-between">
            <Box fontWeight={600} fontSize="24px" color={priceImpactSeverity > 2 ? 'font.alert' : ''}>
              {trade.outputAmount.toString()}
            </Box>
            <Center fontWeight={500}>
              <CurrencyLogo currency={trade.outputCurrency} size={'28px'} />
              <Flex flexDirection="column" ml="8px" alignItems="flex-start">
                <Box fontSize="14px">{trade.outputCurrency.symbol}</Box>
                <Box color="font.secondary" fontSize="12px" fontWeight="400">
                  {trade.outputCurrency.network}
                </Box>
              </Flex>
            </Center>
          </Flex>
          <Center
            justifyContent="space-between"
            color="font.secondary"
            borderTop="1px solid"
            borderColor="border.secondary"
            pt="12px"
          >
            <Box fontSize="12px">{t('teleport.modal.To')}</Box>
            <Flex fontSize="12px" color="font.secondary">
              <NetworkLogo network={trade.outputCurrency.network} size={14} />
              <Box ml="4px" mr="8px">
                {connectorName[trade.outputCurrency.network].account}
              </Box>
              {connectorName[trade.outputCurrency.network].connector} |{' '}
              {connectorName[trade.outputCurrency.network].env}
            </Flex>
          </Center>
        </Flex>
        {(trade.inputCurrency.symbol === 'USDC' || trade.outputCurrency.symbol === 'USDC') && (
          <Flex alignItems="flex-start" mt="12px">
            <AlertIcon fontSize="12px" style={{ fill: '#0075FF', width: '16px' }} />
            <Box fontSize="14px" lineHeight="20px" fontWeight={400} color="font.highlight" ml="8px" w="90%">
              {t('usdcLpMessage')}
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

import { ChainId } from '@uniswap/sdk'
import { useEffect, useState } from 'react'
import { useFclReact, useTokenBalances as useFlowTokenBalances } from '../../fcl-react'
import { useTokenBalances as useSolanaTokenBalances } from '../../solana-react'
import { useTokenBalances as useAptosTokenBalances } from '../../aptos-react'
import useTimer from '../../hooks/useTimer'
import { useWalletActionHandlers } from './flowHooks'
import { useAllCompletedTransactions } from '../transactionsFlow/hooks'

export default function Updater(): null {
  const { chainId, account, connect, disconnect } = useFclReact()
  const [loaded, setLoaded] = useState<ChainId>()
  const nonce = useTimer(10000)
  const allCompletedTxs = useAllCompletedTransactions()
  const flowBalances = useFlowTokenBalances(account, nonce + allCompletedTxs.length)
  const solanaBalances = useSolanaTokenBalances(account, nonce + allCompletedTxs.length)
  const aptosTokenBalances = useAptosTokenBalances(account, nonce + allCompletedTxs.length)
  const { onUpdateBalances } = useWalletActionHandlers()

  useEffect(() => {
    if (!chainId || !connect || !disconnect) return

    if (loaded && chainId !== loaded) {
      disconnect()
      connect()
    }

    setLoaded(chainId)
  }, [chainId, loaded, connect, disconnect])

  useEffect(() => {
    onUpdateBalances({ ...flowBalances, ...solanaBalances, ...aptosTokenBalances })
  }, [flowBalances, solanaBalances, aptosTokenBalances, onUpdateBalances])

  return null
}

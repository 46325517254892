import React from 'react'
import { ListItem } from './layout'
import { Box, Flex, Image, Center, Link, Button } from '@chakra-ui/react'
import FlowIcon from '../../assets/chains/Flow.svg'
import EthIcon from '../../assets/chains/Ethereum.svg'
import BSCIcon from '../../assets/chains/BSC.svg'
import SolanaIcon from '../../assets/chains/Solana.svg'
import AptosIcon from '../../assets/chains/Aptos.svg'
import ChangeIcon from '../../assets/icon/view/change.svg'
import LogoutIcon from '../../assets/icon/content_object/logout.svg'
import NextIcon from '../../assets/icon/navigation/arror_next.svg'
import { useFclReact } from '../../fcl-react'
import { getBSCscanLink, getEtherscanLink, getFvsLink, getSolanaExplorerLink, getAptosExplorerLink } from '../../utils'
import { useWeb3React } from '@web3-react/core'
import { useSolana } from '../../solana-react'
import { useAptos } from '../../aptos-react'
import { Network } from '../../types'
import { SUPPORTED_WALLETS, currentEnv } from '../../constants'
import { injected } from '../../connectors'
import { useTranslation } from 'react-i18next'
import { isEvmChain, removeSessionAddressByChain } from '../../utils'
import Copy from './Copy'

interface ChainCardInterface {
  chain: Network
  onNextStep: (arg?: any) => void
  onChange: (arg?: any) => void
  onDisconnect: (arg?: any) => void
  connectorName: string
  icon: any
  account?: string | null
  address?: string | null
  walletLink: string
}

const CHAIN_NAME_MAPPING: { [network in Network]: string } = {
  [Network.ETHEREUM]: 'Ethereum',
  [Network.BSC]: 'Binance Smart Chain',
  [Network.SOLANA]: 'Solana',
  [Network.FLOW]: 'Flow',
  [Network.APTOS]: 'Aptos'
}

const ChainCard = ({
  chain,
  onNextStep,
  onChange,
  onDisconnect,
  connectorName,
  icon,
  account,
  walletLink
}: ChainCardInterface) => {
  const { t } = useTranslation()
  const sliceLength = chain === Network.FLOW ? 4 : 6
  return account ? (
    <ListItem alignItems="flex-start" cursor="auto">
      <Image src={icon} alt="Flow" boxSize="32px" mt="12px" mr="12px" />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        width="100%"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', sm: 'center' }}
      >
        <Box mr="10px">
          <Flex>
            <Link href={walletLink} isExternal>
              {account.slice(0, sliceLength) + '...' + account.slice(-sliceLength)}
            </Link>
            <Copy text={account} m="0 0 0 5px" />
          </Flex>

          <Box fontSize="14px" color="font.secondary">
            {connectorName} | {currentEnv}
          </Box>
        </Box>
        <Flex mt={{ base: '10px', sm: '0' }}>
          <Button onClick={onChange}>
            <Center boxSize="36px" borderRadius="36px" backgroundColor="white">
              <Image src={ChangeIcon} alt="Change" boxSize="20px" />
            </Center>
          </Button>

          {connectorName !== 'MetaMask' && connectorName !== 'Injected' && (
            <Button onClick={onDisconnect}>
              <Center ml="8px" boxSize="36px" borderRadius="36px" backgroundColor="white">
                <Image src={LogoutIcon} alt="Logout" boxSize="20px" />
              </Center>
            </Button>
          )}
        </Flex>
      </Flex>
    </ListItem>
  ) : (
    <ListItem onClick={onNextStep} pr="40px" fontSize="16px" whiteSpace="normal" cursor="pointer">
      <Image src={icon} alt="Flow" boxSize="32px" mr="12px" />
      <Box textAlign="left">{t('header.wallet.chain_card', { CHAIN_NAME: CHAIN_NAME_MAPPING[chain] })}</Box>
      <Image src={NextIcon} alt=">" boxSize="20px" position="absolute" right="16px" />
    </ListItem>
  )
}

export const FlowCard = ({ onNextStep, onClose }: { onNextStep: () => void; onClose: () => void }) => {
  const { account, disconnect } = useFclReact()
  return (
    <ChainCard
      chain={Network.FLOW}
      onNextStep={onNextStep}
      onChange={() => {
        onNextStep()
      }}
      onDisconnect={() => {
        disconnect()
        onClose()
      }}
      connectorName="Blocto"
      icon={FlowIcon}
      account={account}
      walletLink={getFvsLink(currentEnv === 'mainnet' ? 1 : 5, account, 'account')}
    />
  )
}

export const EthereumCard = ({ onNextStep, onClose }: { onNextStep: () => void; onClose: () => void }) => {
  const { chainId, account: web3Account, connector, deactivate } = useWeb3React()
  const isETH = chainId && isEvmChain(chainId)
  const ethAccount = isETH ? web3Account : null
  function formatConnectorName() {
    const { ethereum } = window

    const isBlocto = !!(ethereum && ethereum.isBlocto)
    if (isBlocto) {
      return 'Blocto'
    }

    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k => SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isBlocto === (k === 'BLOCTO'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return name
  }
  return (
    <ChainCard
      chain={Network.ETHEREUM}
      onNextStep={onNextStep}
      onChange={() => {
        onNextStep()
      }}
      onDisconnect={() => {
        deactivate()
        removeSessionAddressByChain('ethereum')
        onClose()
      }}
      connectorName={formatConnectorName()}
      icon={EthIcon}
      account={ethAccount}
      walletLink={getEtherscanLink(chainId, ethAccount || undefined, 'address')}
    />
  )
}

export const BSCCard = ({ onNextStep, onClose }: { onNextStep: () => void; onClose: () => void }) => {
  const { chainId, account: web3Account, connector, deactivate } = useWeb3React()
  const isBSC = (chainId as number) === 56 || (chainId as number) === 97
  const bscAccount = isBSC ? web3Account : null
  function formatConnectorName() {
    const { ethereum } = window

    const isBlocto = !!(ethereum && ethereum.isBlocto)
    if (isBlocto) {
      return 'Blocto'
    }

    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k => SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isBlocto === (k === 'BLOCTO'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return name
  }
  return (
    <ChainCard
      chain={Network.BSC}
      onNextStep={onNextStep}
      onChange={() => {
        onNextStep()
      }}
      onDisconnect={() => {
        deactivate()
        removeSessionAddressByChain('bsc')
        onClose()
      }}
      connectorName={formatConnectorName()}
      icon={BSCIcon}
      account={bscAccount}
      walletLink={getBSCscanLink(chainId, bscAccount || undefined, 'address')}
    />
  )
}

export const SolanaCard = ({ onNextStep, onClose }: { onNextStep: () => void; onClose: () => void }) => {
  const { disconnect, account } = useSolana()
  return (
    <ChainCard
      chain={Network.SOLANA}
      onNextStep={onNextStep}
      onChange={() => {
        onNextStep()
      }}
      onDisconnect={() => {
        disconnect()
        onClose()
      }}
      connectorName="Blocto"
      icon={SolanaIcon}
      account={account}
      walletLink={getSolanaExplorerLink(currentEnv === 'mainnet' ? 1 : 5, account, 'address')}
    />
  )
}

export const AptosCard = ({ onNextStep, onClose }: { onNextStep: () => void; onClose: () => void }) => {
  const { disconnect, account } = useAptos()
  return (
    <ChainCard
      chain={Network.APTOS}
      onNextStep={onNextStep}
      onChange={() => {
        onNextStep()
      }}
      onDisconnect={() => {
        disconnect()
        onClose()
      }}
      connectorName="Blocto"
      icon={AptosIcon}
      account={account}
      walletLink={getAptosExplorerLink(currentEnv === 'mainnet' ? 1 : 5, account, 'txn')}
    />
  )
}

import { useEffect, useState, useMemo } from 'react'
import * as fcl from '@blocto/fcl'
import * as types from '@onflow/types'
import { ChainId } from '@uniswap/sdk'
type UrlList = { readonly [key: string]: string }

const WALLET_URL: UrlList = {
  mainnet: `https://wallet-v2.blocto.app/api/flow/authn`,
  testnet: `https://wallet-v2-dev.blocto.app/api/flow/authn`
}

const ACCESS_NODE_LIST: UrlList = {
  // mainnet: 'https://flow-access-mainnet.portto.io',
  mainnet: 'https://rest-mainnet.onflow.org',
  testnet: 'https://rest-testnet.onflow.org'
}

// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'

export function useFclReact() {
  const [account, setAccount] = useState<string>()

  const [accessNodeUrl, walletUrl] = useMemo(() => {
    if (!NETWORK) return [undefined, undefined, undefined]

    return [ACCESS_NODE_LIST[NETWORK] || ACCESS_NODE_LIST['testnet'], WALLET_URL[NETWORK] || WALLET_URL['testnet']]
  }, [])

  const connect = useMemo(() => {
    // eslint-disable-next-line
    if (!NETWORK || !walletUrl) return () => {}

    return function() {
      fcl.authenticate()
    }
  }, [walletUrl])

  useEffect(() => {
    if (!accessNodeUrl || !walletUrl) return

    fcl
      .config()
      .put('accessNode.api', accessNodeUrl)
      .put('discovery.wallet', walletUrl)
      .put('discovery.wallet.method', 'HTTP/POST')
      .put('app.detail.id', process.env.REACT_APP_DAPP_ID)
  }, [accessNodeUrl, walletUrl])

  useEffect(() => {
    return fcl.currentUser().subscribe((user: any) => {
      setAccount(user?.addr)
    })
  }, [])

  return useMemo(
    () => ({
      fcl: accessNodeUrl ? fcl : undefined,
      types,
      authorization: fcl.currentUser().authorization,
      // @todo: remove chainId in response
      chainId: NETWORK === 'mainnet' ? ChainId.MAINNET : ChainId.GÖRLI,
      active: !!account,
      account: account,
      // eslint-disable-next-line
      connect: accessNodeUrl ? connect : () => {},
      // eslint-disable-next-line
      disconnect: accessNodeUrl ? fcl.unauthenticate : () => {}
    }),
    [account, accessNodeUrl, connect]
  )
}

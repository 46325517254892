import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFclReact } from '../../fcl-react'
import { TransactionReceipt, Network } from '../../types'
import { getTeleportStatus, TeleportStatus } from '../../teleport'
import { useAddPopup } from '../application/hooks'
import useTimer from '../../hooks/useTimer'
import { useTransactionAdder as useEthTransactionAdder } from '../transactionsEthereum/hooks'
import { useTransactionAdder as useBscTransactionAdder } from '../transactionsBsc/hooks'
import { useTransactionAdder as useSolTransactionAdder } from '../transactionsSolana/hooks'
import { useTransactionAdder as useAptTransactionAdder } from '../transactionsAptos/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'

export function shouldCheck(lastNonce: number, tx: { addedTime: number; receipt?: {}; lastNonce?: number }): boolean {
  if (tx.receipt) return false
  if (!tx.lastNonce) return true
  return lastNonce !== tx.lastNonce
}

export default function Updater(): null {
  const { chainId, fcl } = useFclReact()

  const nonce = useTimer(2000)
  const addEthTransaction = useEthTransactionAdder()
  const addBscTransaction = useBscTransactionAdder()
  const addSolTransaction = useSolTransactionAdder()
  const addAptTransaction = useAptTransactionAdder()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactionsFlow']>(state => state.transactionsFlow)

  const transactions = chainId ? state[chainId] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !fcl || !nonce) return

    Object.keys(transactions)
      .filter(hash => shouldCheck(nonce, transactions[hash]))
      .forEach(hash => {
        fcl
          .send([fcl.getTransactionStatus(hash)])
          .then(fcl.decode)
          .then(async (transaction: TransactionReceipt) => {
            if (fcl.tx.isSealed(transaction)) {
              if (
                (transactions[hash].summary ?? '').match(/Teleport.+from Flow to Ethereum/) &&
                transaction?.statusCode === 0
              ) {
                let token = ''

                if ((transactions[hash].summary ?? '').match(/BLT/)) {
                  token = 'blt'
                } else if ((transactions[hash].summary ?? '').match(/USDT/)) {
                  token = 'usdt'
                } else if ((transactions[hash].summary ?? '').match(/REVV/)) {
                  token = 'revv'
                } else if ((transactions[hash].summary ?? '').match(/ICONS/)) {
                  token = 'icons'
                } else if ((transactions[hash].summary ?? '').match(/USDC/)) {
                  token = 'usdc'
                } else if ((transactions[hash].summary ?? '').match(/SPRT/)) {
                  token = 'sprt'
                } else if ((transactions[hash].summary ?? '').match(/MOXY/)) {
                  token = 'moxy'
                } else {
                  token = 'flow'
                }

                const status = await getTeleportStatus(chainId, Network.FLOW, hash, token)

                if (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED) {
                  addEthTransaction(
                    {
                      hash: status.tx_hash
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        status: transaction.status,
                        statusCode: transaction?.statusCode,
                        transactionHash: hash
                      }
                    })
                  )

                  return
                }
              } else if (
                (transactions[hash].summary ?? '').match(/Teleport.+from Flow to Binance/) &&
                transaction?.statusCode === 0
              ) {
                let token = ''

                if ((transactions[hash].summary ?? '').match(/BLT/)) {
                  token = 'blt'
                } else if ((transactions[hash].summary ?? '').match(/ICONS/)) {
                  token = 'icons'
                } else if ((transactions[hash].summary ?? '').match(/USDC/)) {
                  token = 'usdc'
                } else if ((transactions[hash].summary ?? '').match(/MOXY/)) {
                  token = 'moxy'
                } else {
                  token = 'starly'
                }

                const status = await getTeleportStatus(chainId, Network.FLOW, hash, token)

                if (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED) {
                  addBscTransaction(
                    {
                      hash: status.tx_hash
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        status: transaction.status,
                        statusCode: transaction?.statusCode,
                        transactionHash: hash
                      }
                    })
                  )

                  return
                }
              } else if (
                (transactions[hash].summary ?? '').match(/Teleport.+from Flow to Solana/) &&
                transaction?.statusCode === 0
              ) {
                let token = ''

                if ((transactions[hash].summary ?? '').match(/BLT/)) {
                  token = 'blt'
                } else if ((transactions[hash].summary ?? '').match(/MOXY/)) {
                  token = 'moxy'
                } else {
                  token = 'usdt'
                }

                const status = await getTeleportStatus(chainId, Network.FLOW, hash, token)

                if (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED) {
                  addSolTransaction(
                    {
                      transactionId: status.tx_hash
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        status: transaction.status,
                        statusCode: transaction?.statusCode,
                        transactionHash: hash
                      }
                    })
                  )

                  return
                }
              } else if (
                (transactions[hash].summary ?? '').match(/Teleport.+from Flow to Aptos/) &&
                transaction?.statusCode === 0
              ) {
                const token = (transactions[hash].summary || '').includes('BLT') ? 'blt' : 'flow'

                const status = await getTeleportStatus(chainId, Network.FLOW, hash, token)

                if (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED) {
                  addAptTransaction(
                    {
                      transactionId: status.tx_hash
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        status: transaction.status,
                        statusCode: transaction?.statusCode,
                        transactionHash: hash
                      }
                    })
                  )

                  return
                }
              } else {
                dispatch(
                  finalizeTransaction({
                    chainId,
                    hash,
                    receipt: {
                      status: transaction.status,
                      statusCode: transaction?.statusCode,
                      transactionHash: hash
                    }
                  })
                )

                addPopup(
                  {
                    txn: {
                      hash,
                      success: transaction.statusCode === 0,
                      summary: transactions[hash]?.summary,
                      network: Network.FLOW
                    }
                  },
                  hash
                )

                return
              }
            }

            dispatch(checkedTransaction({ chainId, hash, nonce }))
          })
          .catch((error: Error) => {
            console.log(error)
          })
      })
  }, [
    chainId,
    fcl,
    transactions,
    nonce,
    dispatch,
    addPopup,
    addEthTransaction,
    addBscTransaction,
    addSolTransaction,
    addAptTransaction
  ])

  return null
}

const scriptBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodySolana from 0xTELEPORTSOLANAADDRESS

transaction(amount: UFix64, to: String, toAddressType: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let blockTokenVaultRef = accu.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>() ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex(), toAddressType: toAddressType)
  }
}
`

const scriptMOXY = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import MoxyToken from 0xMOXY_TOKEN_ADDRESS
import TeleportCustodySolana from 0xTELEPORT_MOXY_ADDRESS

transaction(amount: UFix64, to: String, toAddressType: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let moxyTokenVaultRef = accu.borrow<&MoxyToken.Vault>(from: MoxyToken.moxyTokenVaultStorage)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- moxyTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodySolana.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodySolana.TeleportAdmin{TeleportCustodySolana.TeleportUser}>() ?? panic("failed to borrow TeleportCustodySolana.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex(), toAddressType: toAddressType)
  }
}
`

const scripts: { [tokenName: string]: string } = {
  BLT: scriptBLT,
  MOXY: scriptMOXY
}

export default scripts

/* eslint-disable @typescript-eslint/camelcase */
import { useMemo } from 'react'
import { Network, TokenInfo } from '../../types'
import { useFclReact } from '../../fcl-react'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance as useFlowTokenBalance, useTokenBalances as useFlowTokenBalances } from './flowHooks'
import { useTokenBalance as useEthTokenBalance, useAllTokenBalances as useEthTokenBalances } from './ethereumHooks'
import { useSolanaBalance } from '../../solana-react/useSolana'
import { useAptosBalance } from '../../aptos-react'
export function useTokenBalance(token: TokenInfo | undefined): number | undefined {
  const { account: flowAccount } = useFclReact()
  const { account: ethAccount } = useActiveWeb3React()
  const flowTokenBalance = useFlowTokenBalance(flowAccount, token)
  const ethTokenBalance = useEthTokenBalance(ethAccount ?? undefined, token)
  const solTokenBalance = useSolanaBalance(null, token)
  const aptosTokenBalance = useAptosBalance(null, token)

  const result: number | undefined = useMemo(() => {
    switch (token?.network) {
      case Network.FLOW:
        return flowTokenBalance
      case Network.ETHEREUM:
      case Network.BSC:
        return ethTokenBalance
      case Network.SOLANA:
        return solTokenBalance
      case Network.APTOS:
        return aptosTokenBalance
      default:
        return undefined
    }
  }, [flowTokenBalance, ethTokenBalance, solTokenBalance, aptosTokenBalance, token])

  return useMemo(() => {
    return result
  }, [result])
}

export function useAllTokenBalances(): { [tokenName: string]: number | undefined } {
  const flowTokenBalances = useFlowTokenBalances()
  const ethTokenBalances = useEthTokenBalances()

  return useMemo(() => {
    return {
      ...flowTokenBalances,
      ...ethTokenBalances
    }
  }, [flowTokenBalances, ethTokenBalances])
}

import React, { useState, useEffect } from 'react'
import { Image, useDisclosure } from '@chakra-ui/react'
import HeaderButton from './HeaderButtonWithModal'
import WhiteFlowIcon from '../../assets/chains/white/Flow.svg'
import WhiteEthIcon from '../../assets/chains/white/Ethereum.svg'
import WhiteBscIcon from '../../assets/chains/white/BNB.svg'
import WhiteSolanaIcon from '../../assets/chains/white/Solana.svg'
import WhiteAptosIcon from '../../assets/chains/white/Aptossvg.svg'
import { useFclReact } from '../../fcl-react'
import { shortenAddress, isEvmChain } from '../../utils'
import WalletModal from './WalletModal'
import { useSolana } from '../../solana-react'
import { useAptos } from '../../aptos-react'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
const WalletButton = ({ isTeleport }: { isTeleport?: boolean }) => {
  const disclosure = useDisclosure()
  const { account: flowAccount } = useFclReact()
  const { account: solanaAccount } = useSolana()
  const { account: aptosAccount } = useAptos()
  const { t } = useTranslation()
  const { chainId, account: web3Account } = useWeb3React()
  const isETH = chainId && isEvmChain(chainId)
  const ethAccount = isETH ? web3Account : null
  const isBSC = (chainId as number) === 56 || (chainId as number) === 97
  const bscAccount = isBSC ? web3Account : null
  const [teleportIconList, setTeleportIconList] = useState<any>([])

  const icons: { [key: string]: string } = {
    flow: WhiteFlowIcon,
    eth: WhiteEthIcon,
    bsc: WhiteBscIcon,
    solana: WhiteSolanaIcon,
    aptos: WhiteAptosIcon
  }

  const getTeleportIconList = (): string[] => {
    const iconList = [
      flowAccount && 'flow',
      ethAccount && 'eth',
      bscAccount && 'bsc',
      solanaAccount && 'solana',
      aptosAccount && 'aptos'
    ]

    return iconList
      .reduce((acc, curr) => {
        if (curr) acc.push(curr)
        return acc
      }, [] as string[])
      .slice(0, 3)
  }

  useEffect(() => {
    setTeleportIconList(getTeleportIconList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowAccount, ethAccount, bscAccount, solanaAccount, aptosAccount])

  return (
    <>
      <HeaderButton
        variant="primary"
        disclosure={disclosure}
        width="100%"
        modal={<WalletModal onClose={disclosure.onClose} isTeleport={isTeleport} />}
      >
        {flowAccount && !isTeleport && <Image src={WhiteFlowIcon} boxSize="20px" mr="6px" />}
        {isTeleport &&
          Array.isArray(teleportIconList) &&
          teleportIconList.map((type: string) => {
            return <Image key={type} src={icons[type]} boxSize="20px" mr="6px" />
          })}
        {!isTeleport && (flowAccount ? shortenAddress(flowAccount) : t('header.wallet.connect'))}
        {isTeleport &&
          t('header.wallet.wallets') +
            ` (${Number(!!flowAccount) + Number(!!solanaAccount) + Number(!!web3Account) + Number(!!aptosAccount)})`}
      </HeaderButton>
    </>
  )
}
export default WalletButton

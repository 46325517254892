import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Box, Flex, useDisclosure, Center, Link, Image, Progress, Spinner } from '@chakra-ui/react'
import { ReactComponent as EmptyIcon } from '../../assets/images/no-transfer.svg'
import HeaderButton from './HeaderButtonWithModal'
import { ListItem } from './layout'
import { useDispatch } from 'react-redux'
import { clearAllTransactions as clearAllEthTransactions } from '../../state/transactionsEthereum/actions'
import { clearAllTransactions as clearAllBscTransactions } from '../../state/transactionsBsc/actions'
import { clearAllTransactions as clearAllFlowTransactions } from '../../state/transactionsFlow/actions'
import { clearAllTransactions as clearAllSolanaTransactions } from '../../state/transactionsSolana/actions'
import { clearAllTransactions as clearAllAptosTransactions } from '../../state/transactionsAptos/actions'
import { useAllTransactions as useAllEthTransactions } from '../../state/transactionsEthereum/hooks'
import { useAllTransactions as useAllBscTransactions } from '../../state/transactionsBsc/hooks'
import { isTransactionRecent, useAllTransactions as useAllFlowTransactions } from '../../state/transactionsFlow/hooks'
import { useAllTransactions as useAllSolanaTransactions } from '../../state/transactionsSolana/hooks'
import { useAllTransactions as useAllAptTransactions } from '../../state/transactionsAptos/hooks'
import { TransactionAbstract, Network } from '../../types'
import { getBSCscanLink, getEtherscanLink, getFvsLink, getSolanaExplorerLink, getAptosExplorerLink } from '../../utils'
import { useFclReact } from '../../fcl-react'
import NetworkLogo from '../NetworkLogo'
import CheckIcon from '../../assets/icon/status/check-blue.svg'
import ErrorIcon from '../../assets/icon/status/error-red.svg'
import { TransactionReceipt } from '../../types'
import { useTranslation } from 'react-i18next'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionAbstract, b: TransactionAbstract) {
  return b.addedTime - a.addedTime
}

function Transaction({ transaction }: { transaction: TransactionAbstract }) {
  const { chainId } = useFclReact()
  const allEthTransactions = useAllEthTransactions()
  const allBscTransactions = useAllBscTransactions()
  const allFlowTransactions = useAllFlowTransactions()
  const allSolanaTransactions = useAllSolanaTransactions()
  const allAptosTransactions = useAllAptTransactions()
  const { hash, network } = transaction
  const secondsPassed = (Date.now() - transaction.addedTime) / 1000
  const [progress, setProgress] = useState(Math.min(70, secondsPassed * 2.3))

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 70) {
          clearInterval(timer)
          return prev
        }
        return prev + 0.23
      })
    }, 100)
  }, [])

  let tx: any,
    link: any,
    success = false,
    pending = false,
    summary: any

  switch (network) {
    case Network.FLOW:
      tx = allFlowTransactions?.[hash]
      link = getFvsLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = !pending && tx && tx.receipt?.statusCode === 0
      break
    case Network.ETHEREUM:
      tx = allEthTransactions?.[hash]
      link = getEtherscanLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = (!pending && tx && tx.receipt?.status === 1) || typeof tx.receipt?.status === 'undefined'
      break
    case Network.BSC:
      tx = allBscTransactions?.[hash]
      link = getBSCscanLink(chainId, hash, 'transaction')
      pending = !tx?.receipt
      summary = tx?.summary
      success = (!pending && tx && tx.receipt?.status === 1) || typeof tx.receipt?.status === 'undefined'
      break
    case Network.SOLANA:
      tx = allSolanaTransactions?.[hash]
      link = getSolanaExplorerLink(chainId, hash, 'tx')
      pending = !tx?.receipt
      summary = tx?.summary
      success = !pending && tx && tx.receipt?.statusCode === 0
      break
    case Network.APTOS:
      tx = allAptosTransactions?.[hash]
      link = getAptosExplorerLink(chainId, hash, 'txn')
      pending = !tx?.receipt
      summary = tx?.summary
      success = !pending && tx && tx.receipt?.statusCode === 0
      break
  }
  return (
    <ListItem justifyContent="space-between" cursor="auto">
      <Flex width="100%" alignItems="center">
        <Box mr="8px">
          <NetworkLogo network={network} size={24} />
        </Box>
        <Box width="100%">
          <Link
            href={link}
            color="font.primary"
            borderColor="font.primary"
            borderBottom="1px solid"
            fontSize="14px"
            fontWeight="500"
            isExternal
          >
            {summary}
          </Link>
          {pending && <Progress height="4px" mt="8px" borderRadius="full" value={progress} />}
        </Box>
      </Flex>

      {!pending &&
        (success ? (
          <Image src={CheckIcon} boxSize="20px" ml={{ base: '8px', sm: '0' }} />
        ) : (
          <Image src={ErrorIcon} boxSize="20px" />
        ))}
    </ListItem>
  )
}

interface TransactionDisplay {
  receipt: TransactionReceipt | undefined
  hash: string
  addedTime: number
  network: Network
}

const RecentModal = ({
  sortedRecentTransactions,
  pending,
  confirmed
}: {
  sortedRecentTransactions: TransactionDisplay[]
  pending: TransactionDisplay[]
  confirmed: TransactionDisplay[]
}) => {
  const { chainId } = useFclReact()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const clearAllTransactionsCallback = useCallback(() => {
    if (!chainId) return
    dispatch(clearAllEthTransactions({ chainId }))
    dispatch(clearAllBscTransactions({ chainId }))
    dispatch(clearAllFlowTransactions({ chainId }))
    dispatch(clearAllSolanaTransactions({ chainId }))
    dispatch(clearAllAptosTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <Box bgColor="white">
      <Flex
        height="54px"
        width="100%"
        p="16px 24px"
        borderBottom="1px solid"
        alignItems="center"
        borderColor="border.secondary"
        fontWeight={500}
        position="relative"
      >
        {t('header.recent.title')}
        <Center
          position="absolute"
          right="24px"
          backgroundColor="interaction.secondary"
          p="4px 12px"
          borderRadius="full"
          fontSize="14px"
          cursor="pointer"
          onClick={clearAllTransactionsCallback}
        >
          {t('header.recent.clear')}
        </Center>
      </Flex>
      {sortedRecentTransactions.length ? (
        <Flex p="24px" width="100%" flexDirection="column" gap="12px">
          {pending.map((transaction, i) => (
            <Transaction key={i} transaction={transaction} />
          ))}
          {confirmed.map((transaction, i) => (
            <Transaction key={i} transaction={transaction} />
          ))}
        </Flex>
      ) : (
        <Center minHeight="300px" flexDirection="column">
          <EmptyIcon />
          <Box color="font.tertiary">{t('header.recent.no_transactions')}</Box>
        </Center>
      )}
    </Box>
  )
}

const RecentButton = () => {
  const disclosure = useDisclosure()
  const allEthTransactions = useAllEthTransactions()
  const allBscTransactions = useAllBscTransactions()
  const allFlowTransactions = useAllFlowTransactions()
  const allSolanaTransactions = useAllSolanaTransactions()
  const allAptosTransactions = useAllAptTransactions()

  const { t } = useTranslation()

  const sortedRecentTransactions = useMemo(() => {
    const ethTxs = Object.values(allEthTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.ETHEREUM
    }))
    const bscTxs = Object.values(allBscTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.BSC
    }))
    const flowTxs = Object.values(allFlowTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.FLOW
    }))
    const solTxs = Object.values(allSolanaTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.SOLANA
    }))
    const aptTxs = Object.values(allAptosTransactions).map(({ receipt, hash, addedTime }) => ({
      receipt,
      hash,
      addedTime,
      network: Network.APTOS
    }))
    return ethTxs
      .concat(bscTxs)
      .concat(flowTxs)
      .concat(solTxs)
      .concat(aptTxs)
      .filter(isTransactionRecent)
      .sort(newTransactionsFirst)
  }, [allEthTransactions, allBscTransactions, allFlowTransactions, allSolanaTransactions, allAptosTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt)
  return (
    <>
      <HeaderButton
        variant="secondary"
        disclosure={disclosure}
        color="font.highlight"
        modal={
          <RecentModal sortedRecentTransactions={sortedRecentTransactions} pending={pending} confirmed={confirmed} />
        }
        width="100%"
      >
        {!!pending.length && (
          <Center mr="6px">
            <Spinner color="font.highlight" size="sm" thickness="1.5px" />
          </Center>
        )}
        {t('header.recent.button')}
        {!!pending.length && <Box ml="5px">({pending.length > 9 ? '9+' : pending.length})</Box>}
      </HeaderButton>
    </>
  )
}
export default RecentButton

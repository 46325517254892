import { useMemo, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../state/index'
import BloctoSDK from '@blocto/sdk'
import { SolanaContext } from './SolanaContext'
import { TokenInfo } from '../types'
import { Trade } from '../fcl-react'
import { teleportIn } from './teleport'
import { PublicKey, Connection } from '@solana/web3.js'
import { useFclReact } from '../fcl-react/useFclReact'
import { useTransactionAdder } from '../state/transactionsSolana/hooks'
import { currentEnv } from '../constants'
import { removeSessionAddressByChain } from '../utils'

const NET = currentEnv === 'mainnet' ? 'mainnet-beta' : 'devnet'
const API_ENDPOINT = currentEnv === 'mainnet' ? 'https://blocto.rpcpool.com' : 'https://api.devnet.solana.com'

const bloctoSDK = new BloctoSDK({
  solana: {
    net: NET,
    rpc: API_ENDPOINT
  }
})

export function useSolana() {
  const { solana, setSolana } = useContext<any>(SolanaContext)
  const { chainId } = useFclReact()

  const connect = useMemo(() => {
    return async () => {
      if (bloctoSDK.solana) {
        await bloctoSDK.solana.connect()
        setSolana(bloctoSDK.solana)
      }
    }
  }, [setSolana])

  const disconnect = useMemo(() => {
    return () => {
      if (solana) {
        solana.request({ method: 'disconnect' })
        removeSessionAddressByChain('solana')
        setSolana(null)
      }
    }
  }, [solana, setSolana])

  return useMemo(
    () => ({
      chainId,
      connect,
      disconnect,
      account: solana?.session?.accounts?.solana?.[0],
      net: NET,
      solana,
      connection: new Connection(API_ENDPOINT)
    }),
    [connect, disconnect, solana, chainId]
  )
}

export function useSolanaBalance(uncheckedAddress: string | null, token?: TokenInfo | null): number | undefined {
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const result = token ? wallet.balances?.[token.address] : undefined
  return result
}

export enum TeleportCallbackState {
  INVALID,
  LOADING,
  VALID
}

export function useTeleportCallback(
  trade: Trade | undefined // trade to execute, required
): { state: TeleportCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { solana } = useContext<any>(SolanaContext)
  const { account: flowAccount } = useFclReact()
  const { account: solAccount, connection } = useSolana()
  const wallet = useRef<any>()
  const addTransaction = useTransactionAdder()
  useEffect(() => {
    if (solAccount && solana) {
      const pubKey = new PublicKey(solAccount)
      wallet.current = Object.create(solana)
      wallet.current.publicKey = pubKey
    }
  }, [solAccount, solana])

  const formattedInput = trade ? parseInt(trade.inputAmount.toFixed(8).replace('.', '')) : 0

  const outputCallback = () => {
    return !flowAccount || !trade
      ? null
      : async function onTeleport(): Promise<string> {
          const outputSymbol = trade.outputCurrency.symbol
          return teleportIn(connection, wallet.current, outputSymbol, formattedInput, 8, flowAccount)
            .then(response => {
              const inputAmount = trade.inputAmount.toFixed(4)

              const summary = `Teleport ${inputAmount} ${outputSymbol} from Solana to Flow`
              addTransaction(
                { transactionId: response },
                {
                  summary
                }
              )
              return response
            })
            .catch((error: Error) => {
              // if the user rejected the tx, pass this along
              if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
                throw new Error('Transaction rejected.')
              } else {
                // otherwise, the error was unexpected and we need to convey that
                console.error(`Teleport failed`, error)
                throw new Error(`Teleport failed: ${error.message}`)
              }
            })
        }
  }
  return {
    state: TeleportCallbackState.VALID,
    callback: outputCallback(),
    error: null
  }
}

import BN from 'bn.js'
import BufferLayout from 'buffer-layout'
import * as SPLToken from '@solana/spl-token'
import { Connection, PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js'
import {
  WALLET_PROGRAM,
  BLT_FLOW_TELEPORT_CONFIG,
  BLT_FLOW_TELEPORT_PROGRAM,
  BLT_FLOW_TELEPORT_SIGNER,
  BLT_TOKEN_MINT,
  BLT_WALLET_ACCOUNT_PDA,
  BLT_WALLET_ACCOUNT,
  MOXY_FLOW_TELEPORT_CONFIG,
  MOXY_FLOW_TELEPORT_PROGRAM,
  MOXY_FLOW_TELEPORT_SIGNER,
  MOXY_TOKEN_MINT,
  MOXY_WALLET_ACCOUNT_PDA,
  MOXY_WALLET_ACCOUNT
} from './env'

export const commitment = 'confirmed'

export async function sendTransaction(connection: Connection, wallet: any, transaction: Transaction) {
  if (wallet.signAndSendTransaction) {
    transaction.recentBlockhash = (await connection.getRecentBlockhash(commitment)).blockhash
    transaction.feePayer = wallet.publicKey
    const response = await wallet.signAndSendTransaction(transaction)
    const txHash = response?.signature ?? response // TODO: app is object but web is string
    return txHash
  }
}

export function instructionTeleportIn(
  walletProgramID: PublicKey,
  walletAccount: PublicKey,
  walletAccountSigner: PublicKey,
  walletDerrivedAddress: PublicKey,
  teleportProgramID: PublicKey,
  teleportConfig: PublicKey,
  from: PublicKey,
  fromAuth: PublicKey,
  mint: PublicKey,
  to: string,
  amount: number,
  deciamls: number
) {
  const dataLayout = BufferLayout.struct([
    BufferLayout.u8('instruction'),
    BufferLayout.blob(8, 'amount'),
    BufferLayout.u8('decimals'),
    BufferLayout.u32('toLen'),
    BufferLayout.blob(8, 'to')
  ])
  const data = Buffer.alloc(dataLayout.span)

  if (to.slice(0, 2) === '0x') {
    to = to.slice(2, to.length)
  }

  const toBuffer = new BN(to, 'hex', 'be').toArrayLike(Buffer, 'be', 8)
  dataLayout.encode(
    {
      instruction: 8,
      amount: new BN(amount).toArrayLike(Buffer, 'le', 8),
      decimals: deciamls,
      toLen: toBuffer.length,
      to: toBuffer
    },
    data
  )

  const instruction = new TransactionInstruction({
    keys: [
      { pubkey: teleportConfig, isSigner: false, isWritable: false },
      { pubkey: walletAccount, isSigner: false, isWritable: true },
      { pubkey: walletDerrivedAddress, isSigner: false, isWritable: false },
      { pubkey: walletAccountSigner, isSigner: false, isWritable: false },
      { pubkey: walletProgramID, isSigner: false, isWritable: false },
      { pubkey: from, isSigner: false, isWritable: true },
      { pubkey: mint, isSigner: false, isWritable: true },
      { pubkey: fromAuth, isSigner: true, isWritable: false },
      { pubkey: SPLToken.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ],
    programId: teleportProgramID,
    data: data
  })

  return instruction
}

export async function teleportIn(
  connection: Connection,
  wallet: any,
  tokenSymbol: string,
  amount: number,
  decimals: number,
  to: string
) {
  if (wallet.publicKey) {
    const pda = wallet.publicKey
    const mint = tokenSymbol === 'MOXY' ? MOXY_TOKEN_MINT : BLT_TOKEN_MINT
    const teleportSigner = tokenSymbol === 'MOXY' ? MOXY_FLOW_TELEPORT_SIGNER : BLT_FLOW_TELEPORT_SIGNER
    const teleportProgram = tokenSymbol === 'MOXY' ? MOXY_FLOW_TELEPORT_PROGRAM : BLT_FLOW_TELEPORT_PROGRAM
    const teleportConfig = tokenSymbol === 'MOXY' ? MOXY_FLOW_TELEPORT_CONFIG : BLT_FLOW_TELEPORT_CONFIG
    const walletAccount = tokenSymbol === 'MOXY' ? MOXY_WALLET_ACCOUNT : BLT_WALLET_ACCOUNT
    const walletAccountPda = tokenSymbol === 'MOXY' ? MOXY_WALLET_ACCOUNT_PDA : BLT_WALLET_ACCOUNT_PDA

    // get asso token address
    const ata = (
      await PublicKey.findProgramAddress(
        [pda.toBuffer(), SPLToken.TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        SPLToken.ASSOCIATED_TOKEN_PROGRAM_ID
      )
    )[0]

    // blocto
    if (wallet.signAndSendTransaction) {
      const feePayerPubkey = new PublicKey('Fj2dZGnbH81hZy8wQu9TTJ5q73cnmv8qCCLF8iz7R2pt')
      const tx = new Transaction().add(
        instructionTeleportIn(
          WALLET_PROGRAM,
          walletAccount,
          teleportSigner,
          walletAccountPda,
          teleportProgram,
          teleportConfig,
          ata,
          pda,
          mint,
          to,
          amount,
          decimals
        )
      )
      tx.feePayer = feePayerPubkey

      return await sendTransaction(connection, wallet, tx)
    }
  } else {
    throw new Error('no wallet.publicKey')
  }
}

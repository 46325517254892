const scriptBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let blockTokenVaultRef = accu.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>() ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scriptSTARLY = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportCustodyBSC from 0xTELEPORTSTARLYADDRESS

transaction(amount: UFix64, target: String) {

    // The TeleportUser reference for teleport operations
    let teleportUserRef: &TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}

    // The Vault resource that holds the tokens that are being transferred
    let sentVault: @FungibleToken.Vault

    prepare(signer: AuthAccount) {
        self.teleportUserRef = getAccount(0xTELEPORTSTARLYADMINADDRESS).getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)
            .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>()
            ?? panic("Could not borrow a reference to TeleportOut")

        let vaultRef = signer.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
            ?? panic("Could not borrow a reference to the vault resource")

        self.sentVault <- vaultRef.withdraw(amount: amount)
    }

    execute {
        self.teleportUserRef.lock(from: <- self.sentVault, to: target.decodeHex())
    }
}
`

const scriptICONS = `\
import IconsToken from 0xICONS_ADDRESS
import TeleportCustody from 0xICONS_TELEPORT_ADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: AuthAccount) {
    let teleportUserRef = getAccount(0xTELEPORT_ICONS_ADMIN_ADDRESS).getCapability(TeleportCustody.TeleportUserPublicPath)
        .borrow<&TeleportCustody.TeleportAdmin{TeleportCustody.TeleportUser}>()
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.borrow<&IconsToken.Vault>(from: IconsToken.VaultStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- (vault as! @IconsToken.Vault), to: targetAddress.decodeHex())
  }
}
`

const scriptMOXY = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import MoxyToken from 0xMOXY_TOKEN_ADDRESS
import TeleportCustodyBSC from 0xTELEPORT_MOXY_ADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @FungibleToken.Vault

  prepare(accu: AuthAccount) {
    let moxyTokenVaultRef = accu.borrow<&MoxyToken.Vault>(from: MoxyToken.moxyTokenVaultStorage)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- moxyTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORT_MOXY_ADMIN_ADDRESS)
      .getCapability(TeleportCustodyBSC.TeleportAdminTeleportUserPath)!
      .borrow<&TeleportCustodyBSC.TeleportAdmin{TeleportCustodyBSC.TeleportUser}>() ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scripts: { [tokenName: string]: string } = {
  BLT: scriptBLT,
  STARLY: scriptSTARLY,
  ICONS: scriptICONS,
  MOXY: scriptMOXY
}

export default scripts

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFclReact } from '../../fcl-react'
import { Network } from '../../types'
import { getTeleportStatus, TeleportStatus } from '../../teleport'
import { useAddPopup } from '../application/hooks'
import useTimer from '../../hooks/useTimer'
import { useTransactionAdder as useFlowTransactionAdder } from '../transactionsFlow/hooks'
import { AppDispatch, AppState } from '../index'
import { useSolana } from '../../solana-react'
import { checkedTransaction, finalizeTransaction } from './actions'

export function shouldCheck(lastNonce: number, tx: { addedTime: number; receipt?: {}; lastNonce?: number }): boolean {
  if (tx.receipt) return false
  if (!tx.lastNonce) return true
  return lastNonce !== tx.lastNonce
}

export default function Updater(): null {
  const { chainId, fcl } = useFclReact()

  const nonce = useTimer(2000)
  const addFlowTransaction = useFlowTransactionAdder()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactionsSolana']>(state => state.transactionsSolana)
  const transactions = chainId ? state[chainId] ?? {} : {}
  const connection = useSolana().connection

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !connection || !nonce) return

    Object.keys(transactions)
      .filter(hash => shouldCheck(nonce, transactions[hash]))
      .forEach(hash => {
        connection.getTransaction(hash).then((tx: any) => {
          if (tx?.meta) {
            if ((transactions[hash].summary ?? '').match(/Teleport.+from Solana to Flow/)) {
              ;(async () => {
                let token = ''

                if ((transactions[hash].summary ?? '').match(/BLT/)) {
                  token = 'blt'
                } else {
                  token = 'moxy'
                }

                const status = await getTeleportStatus(chainId, Network.SOLANA, hash, token)
                if (status.status === TeleportStatus.SENT || status.status === TeleportStatus.CONFIRMED) {
                  addFlowTransaction(
                    {
                      transactionId: status.tx_hash.replace('0x', '')
                    },
                    {
                      summary: transactions[hash].summary
                    }
                  )

                  dispatch(
                    finalizeTransaction({
                      chainId,
                      hash,
                      receipt: {
                        status: 0,
                        statusCode: 0,
                        transactionHash: hash
                      }
                    })
                  )

                  return
                }
              })()
            } else {
              addPopup(
                {
                  txn: {
                    hash,
                    success: true,
                    summary: transactions[hash]?.summary,
                    network: Network.SOLANA
                  }
                },
                hash
              )
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    status: 0,
                    statusCode: 0,
                    transactionHash: hash
                  }
                })
              )
            }
          } else {
            dispatch(checkedTransaction({ chainId, hash, nonce }))
          }
        })
      })
  }, [chainId, fcl, transactions, nonce, dispatch, addPopup, connection, addFlowTransaction])

  return null
}

import { useMemo, useState, useEffect, useCallback } from 'react'
import { FlowTokenMetadata, TokenInfo } from '../types'
import { useFclReact } from './useFclReact'
import { useTransactionAdder, useHasPendingEnable } from '../state/transactionsFlow/hooks'
import { TransactionResponse } from '../types'
import enableToken from './transactions/enableToken'
import { replaceContractAddresses } from './env'
import useFlowTokenMetadata from './useFlowTokenMetadata'
import { ChainId } from '@uniswap/sdk'
import { useTranslation } from 'react-i18next'

const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = NETWORK === 'mainnet' ? ChainId.MAINNET : ChainId.GÖRLI

export enum EnableState {
  UNKNOWN,
  NOT_ENABLED,
  PENDING,
  ENABLED
}

const checkEnabledScriptBuilder = (token: FlowTokenMetadata | undefined) => {
  if (!token) return
  return `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import ${token.name} from ${token.address}

pub fun main(account: Address): Bool {
  let receiverRef = getAccount(account).getCapability(${token.receiverPath})
      .borrow<&${token.name}.Vault{FungibleToken.Receiver}>()

  return receiverRef != nil
}
`
}

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useEnableCallback(token?: TokenInfo): [EnableState, () => Promise<void>] {
  const { fcl, account, authorization, types } = useFclReact()
  const pendingEnable = useHasPendingEnable(token?.address)
  const [enabled, setEnabled] = useState<boolean | undefined>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const addTransaction = useTransactionAdder()
  const tokensMetadata = useFlowTokenMetadata()
  const { t } = useTranslation()

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!token?.symbol) {
      return callback
    }

    const tokenMetadata = tokensMetadata.find(metadata => token.symbol === metadata.symbol)

    let script = checkEnabledScriptBuilder(tokenMetadata)

    if (!script) {
      setEnabled(true)
      return callback
    }
    script = replaceContractAddresses(script, chainId)

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(account, types.Address)])])
      .then(fcl.decode)
      .then((result: boolean) => {
        isSubscribed && setEnabled(result)
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [token, fcl, types, account, tokensMetadata])

  // check the current approval status
  const enableState: EnableState = useMemo(() => {
    if (!token) return EnableState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (enabled === undefined) return EnableState.UNKNOWN

    return !enabled ? (pendingEnable || submitted ? EnableState.PENDING : EnableState.NOT_ENABLED) : EnableState.ENABLED
  }, [enabled, pendingEnable, token, submitted])

  const enable = useCallback((): Promise<void> => {
    if (!token?.symbol) {
      return Promise.reject()
    }

    const enableScript = replaceContractAddresses(enableToken[token?.symbol] ?? '', chainId)

    if (!enableScript) {
      return Promise.reject()
    }

    setSubmitted(true)

    const isSealed = false
    return fcl
      .send([fcl.getBlock(isSealed)])
      .then(fcl.decode)
      .then((block: any) =>
        fcl.send([
          fcl.transaction(enableScript),
          fcl.limit(100),
          fcl.proposer(authorization),
          fcl.authorizations([authorization]),
          fcl.payer(authorization),
          fcl.ref(block.id)
        ])
      )
      .then((response: TransactionResponse) => {
        const summary = t('popup.transaction.enable', { TOKEN: token.symbol })

        addTransaction(response, {
          summary,
          approval: { tokenAddress: token.address }
        })

        const unsub = fcl.tx(response.transactionId).subscribe((transaction: TransactionResponse) => {
          if (fcl.tx.isExecuted(transaction)) {
            setSubmitted(false)
            unsub()

            if (transaction.statusCode === 0) {
              setEnabled(true)
            }
          }
        })

        return response.transactionId
      })
      .catch((error: Error) => {
        setSubmitted(false)
        alert(error?.message)

        // if the user rejected the tx, pass this along
        if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
          throw new Error('Transaction rejected.')
        } else {
          // otherwise, the error was unexpected and we need to convey that
          console.error(`Enable failed`, error, enableScript)
          throw new Error(`Enable failed: ${error.message}`)
        }
      })
  }, [fcl, token, authorization, addTransaction, t])

  return useMemo(() => [enableState, enable], [enableState, enable])
}
